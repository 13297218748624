<div #finesseContainer class="finesse-container">
  <div #connectionStatusBar class="connection-status-bar"></div>
  <div *ngIf="currentUiState === UI_STATE.LOGGED_IN">
    <amc-scenario [scenario]="scenario"></amc-scenario>

    <div *ngFor="let wrapupInteraction of wrapupInteractions">
      <amc-disposition
      [disposition]="wrapupInteraction"
      (dispositionEmitter)="dispositionSelected($event)"
      ></amc-disposition>

    </div>
  </div>

  <div #userNotLoggedIn *ngIf="currentUiState === UI_STATE.LOGGED_OUT">
    <amc-login
    [loginData]="loginData"
    (loginDetailsProvided)="loginToJabber($event)"></amc-login>
  </div>

  <div #deviceSelection class="deviceSelection" *ngIf="currentUiState === UI_STATE.DEVICE_SELECTION">
    <app-device-selection
    [devices]="devices"
    (deviceSelected)="deviceSelected($event)"
    ></app-device-selection>
  </div>
</div>

<span #loginText class="loginText">Logging in to Finesse&nbsp;&nbsp;&nbsp;</span>
