import { IDisposition } from './../models/IDisposition.interface';
import { UiOperationsService } from './../services/ui-operations.service';
import { IScenario, ILoginData } from '@amc-technology/ui-library';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FinesseService } from '../services/finesse.service';
import { UserService } from '../services/user.service';
import { setAppHeight, sendNotification, NOTIFICATION_TYPE } from '@amc-technology/davinci-api';
import { GlobalConstants } from '../common/globalconstants';
import { LoggerService } from '../services/logger.service';
import { JABBER_CONNECTION_STATUS } from '../models/JabberConnectionStatus.enum';
import { Device } from '../models/IDevice';
import { UI_STATES } from '../models/UIStates';

@Component({
  selector: 'app-home',
  templateUrl: './home-finesse.component.html',
  styleUrls: ['./home-finesse.component.css'],
})
export class HomeComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @ViewChild('finesseContainer', { static: false }) finesseContainerEl: ElementRef;
  @ViewChild('loginText', { static: false }) loginTextEl: ElementRef;
  @ViewChild('connectionStatusBar', { static: false }) connectionStatusBarEl: ElementRef;

  scenario: IScenario;
  wrapupInteractions: IDisposition[];
  height: number;
  loginData: ILoginData;
  localStorage: any;
  loginAnimationInterval: any;
  dotsGoingUp: boolean;
  devices: Device[];
  currentUiState: number;
  UI_STATE: typeof UI_STATES;

  constructor(
    private _user: UserService,
    private _finesse: FinesseService,
    private _operations: UiOperationsService,
    private _loggerService: LoggerService,
    private _renderer: Renderer2
  ) {
    this.initVariables();
  }

  async ngAfterViewInit(): Promise<void> {
    try {
      this._loggerService.logger.logInformation('FINESSE - Home Component - ngAfterViewInit: START');

      this._finesse.jabberConnectionStatusSubject.subscribe({
        next: (status) => {
          switch (status) {
          case JABBER_CONNECTION_STATUS.CONNECTED:
            this._renderer.setStyle(this.connectionStatusBarEl.nativeElement, 'background-color', 'green');
            break;
          case JABBER_CONNECTION_STATUS.DISCONNECTED:
          case JABBER_CONNECTION_STATUS.DISCONNECTING:
            this._renderer.setStyle(this.connectionStatusBarEl.nativeElement, 'background-color', 'red');
            break;
          case JABBER_CONNECTION_STATUS.CONNECTING:
          case JABBER_CONNECTION_STATUS.RECONNECTING:
            this._renderer.setStyle(this.connectionStatusBarEl.nativeElement, 'background-color', 'yellow');
            break;
          }
        }
      });

      this._loggerService.logger.logInformation('FINESSE - Home Component - ngAfterViewInit: END');
    } catch (e) {
      this._loggerService.logger.logError(`FINESSE - Home Component - ngAfterViewInit - Error : ${JSON.stringify(e)}`);
    }
  }

  ngAfterViewChecked() {
    if (this.finesseContainerEl.nativeElement.scrollHeight + 22 !== this.height) {
      this.height = this.finesseContainerEl.nativeElement.scrollHeight + 22;
      setAppHeight(this.height);
    }
  }

  async ngOnInit() {
    try {
      this._loggerService.logger.logInformation('FINESSE - Home Component - ngOnInit: START');

      await this._finesse.setup();
      this._operations.setFinesseService(this._finesse);

      if (this.userDetailsExist()) {
        await this.loginToJabber();
      }

      this._loggerService.logger.logInformation('FINESSE - Home Component - ngOnInit: END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - Home Component - ngOnInit - Error : ${error.message || error}`);
    }
  }

  async loginToJabber(event?: ILoginData) {
    try {
      this._loggerService.logger.logInformation('FINESSE - Home Component - login: START');

      this.toggleLoginLoad(true);

      if (event) {
        this.getLoginDetailsFromEvent(event);
      } else {
        this.getLoginDetailsFromLocalStorage();
        this.height = 120;
        setAppHeight(this.height);
      }

      this._user.setCredentials();

      // TODO: Do device stuff here, before connecting to jabberwerx
      const numDevices = await this._finesse.performDeviceValidation();

      if (numDevices === 1) {
        await this._finesse.connectJabberwerx();
      }

      this._loggerService.logger.logInformation('FINESSE - Home Component - login: END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - Home Component - login - Error : ${error.message || error}`);
    }
  }

  dispositionSelected(event) {
    try {
      this._loggerService.logger.logInformation('FINESSE - Home Component - dispositionSelected: START');

      this._finesse.receivedDisposition(event);

      this._loggerService.logger.logInformation('FINESSE - Home Component - dispositionSelected: END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - Home Component - dispositionSelected - Error : ${error.message || error}`);
    }
  }

  deviceSelected(event: Device) {
    try {
      this._loggerService.logger.logInformation('FINESSE - Home Component - deviceSelected: START');

      this._user.device = event;
      this.toggleLoginLoad(true);
      this._finesse.connectJabberwerx();

      this._loggerService.logger.logInformation('FINESSE - Home Component - deviceSelected - END');
    } catch (e) {
      this._loggerService.logger.logError(`FINESSE - Home Component - deviceSelected - Error : ${JSON.stringify(e)}`);
    }
  }

  private initVariables() {
    try {
      this._loggerService.logger.logInformation('FINESSE - Home Component - initVariables: START');

      this.localStorage = window.localStorage;
      this.dotsGoingUp = true;
      this.UI_STATE = UI_STATES;
      this.currentUiState = this.UI_STATE.LOGGED_OUT;

      this.scenario = { interactions: [] };
      this.wrapupInteractions = [];
      this.devices = [];

      this._finesse.loginSubject.subscribe({
        next: (data) => {
          this.toggleLoginLoad(false);

          if (data) {
            if (this._user.device) {
              this.localStorage.setItem(GlobalConstants.DEVICE_KEY, btoa(JSON.stringify(this._user.device)));
            }

            this.currentUiState = this.UI_STATE.LOGGED_IN;
            this.height = 22;
            setAppHeight(this.height);
          } else {
            this.currentUiState = this.UI_STATE.LOGGED_OUT;
          }
        },
      });

      this._finesse.callSubject.subscribe({
        next: (scenario) => {
          this.scenario = scenario;
        },
      });

      this._finesse.dispositionSubject.subscribe({
        next: (wrapupInteractions) => {
          this.wrapupInteractions = wrapupInteractions;
        },
      });

      this._finesse.deviceSelectionSubject.subscribe({
        next: (devices) => {
          this.devices = devices;

          if (devices.length > 1) {
            this.toggleLoginLoad(false);
            this.currentUiState = this.UI_STATE.DEVICE_SELECTION;

            sendNotification('Please select a device to login with into Cisco Finesse', NOTIFICATION_TYPE.Information);
          }
        },
      });

      this.loginData = this.createLoginFormData();

      this._loggerService.logger.logInformation('FINESSE - Home Component - initVariables: END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - Home Component - initVariables - Error : ${error.message || error}`);
    }
  }

  // TODO: Preloaded values not working
  private createLoginFormData(): ILoginData {
    try {
      this._loggerService.logger.logInformation('FINESSE - Home Component - createLoginFormData - Creating and returning form data');

      return {
        header: 'Login To Finesse',
        fields: [
          {
            name: 'Username',
            type: 'text',
            isRequired: true,
            invalidMessage: 'Username is required'
          },
          {
            name: 'Extension',
            type: 'text',
            isRequired: true,
            invalidMessage: 'Extension is required'
          },
          {
            name: 'Domain',
            type: 'text',
            isRequired: true,
            invalidMessage: 'Domain is required'
          },
          {
            name: 'Password',
            type: 'password',
            isRequired: true,
            invalidMessage: 'Password is required'
          },
        ],
      };
    } catch (e) {
      this._loggerService.logger.logError(`FINESSE - Home Component - createLoginFormData - Error : ${JSON.stringify(e)}`);
    }
  }

  private getLoginDetailsFromEvent(event?: ILoginData) {
    try {
      this._loggerService.logger.logInformation('FINESSE - Home Component - getLoginDetailsFromEvent : START');

      this._user.username = event.fields[0].value;
      this._user.extension = event.fields[1].value;
      this._user.domain = event.fields[2].value;
      this._user.password = event.fields[3].value;

      this._loggerService.logger.logInformation('FINESSE - Home Component - getLoginDetailsFromEvent : END');
    } catch (e) {
      this._loggerService.logger.logError(`FINESSE - Home Component - getLoginDetailsFromEvent - Error : ${e.message || e}`);
    }
  }

  private getLoginDetailsFromLocalStorage() {
    try {
      this._loggerService.logger.logInformation('FINESSE - Home Component - getLoginDetailsFromLocalStorage : START');

      this._user.username = atob(this.localStorage.getItem(GlobalConstants.USERNAME_KEY));
      this._user.extension = atob(this.localStorage.getItem(GlobalConstants.EXTENSION_KEY));
      this._user.domain = atob(this.localStorage.getItem(GlobalConstants.DOMAIN_KEY));
      this._user.password = atob(this.localStorage.getItem(GlobalConstants.PWD_KEY));
      this._user.device = JSON.parse(atob(this.localStorage.getItem(GlobalConstants.DEVICE_KEY)));

      this._loggerService.logger.logInformation('FINESSE - Home Component - getLoginDetailsFromLocalStorage : END');
    } catch (e) {
      this._loggerService.logger.logError(`FINESSE - Home Component - getLoginDetailsFromLocalStorage - Error : ${e.message || e}`);
    }
  }

  private userDetailsExist(): boolean {
    try {
      this._loggerService.logger.logInformation('FINESSE - Home Component - userDetailsExist : START');

      if (this.localStorage.getItem(GlobalConstants.USERNAME_KEY) &&
      this.localStorage.getItem(GlobalConstants.EXTENSION_KEY) &&
      this.localStorage.getItem(GlobalConstants.DOMAIN_KEY) &&
      this.localStorage.getItem(GlobalConstants.PWD_KEY)) {
        this._loggerService.logger.logInformation('FINESSE - Home Component - userDetailsExist : User details found - END');
        return true;
      } else {
        this._loggerService.logger.logInformation('FINESSE - Home Component - userDetailsExist : User details not found - END');
        return false;
      }
    } catch (e) {
      this._loggerService.logger.logError(`FINESSE - Home Component - userDetailsExist - Error : ${JSON.stringify(e)}`);
    }
  }

  private toggleLoginLoad(startAnimation: boolean) {
    try {
      this._loggerService.logger.logInformation('FINESSE - Home Component - toggleLoginLoad : START');

      if (startAnimation) {
        this._loggerService.logger.logInformation('FINESSE - Home Component - toggleLoginLoad : Starting animation');

        this._renderer.setStyle(this.loginTextEl.nativeElement, 'display', 'block');
        this._renderer.setStyle(this.finesseContainerEl.nativeElement, 'filter', 'blur(5px)');
        this._renderer.setStyle(this.finesseContainerEl.nativeElement, '-webkit-filter', 'blur(5px)');

        this.loginAnimationInterval = window.setInterval(() => {
          if (this.dotsGoingUp ) {
            let newText = 'Logging in to Finesse&nbsp;&nbsp;&nbsp;';

            switch (this.loginTextEl.nativeElement.innerHTML) {
            case 'Logging in to Finesse&nbsp;&nbsp;&nbsp;':
              newText = 'Logging in to Finesse.&nbsp;&nbsp;';
              break;

            case 'Logging in to Finesse.&nbsp;&nbsp;':
              newText = 'Logging in to Finesse..&nbsp;';
              break;

            case 'Logging in to Finesse..&nbsp;':
              newText = 'Logging in to Finesse...';
              break;
            }

            this._renderer.setProperty(this.loginTextEl.nativeElement, 'innerHTML', newText);

            if (this.loginTextEl.nativeElement.innerHTML === 'Logging in to Finesse...') {
              this.dotsGoingUp = false;
            }
          } else {
            let newText = 'Logging in to Finesse...';

            switch (this.loginTextEl.nativeElement.innerHTML) {
            case 'Logging in to Finesse...':
              newText = 'Logging in to Finesse..&nbsp;';
              break;

            case 'Logging in to Finesse..&nbsp;':
              newText = 'Logging in to Finesse.&nbsp;&nbsp;';
              break;

            case 'Logging in to Finesse.&nbsp;&nbsp;':
              newText = 'Logging in to Finesse&nbsp;&nbsp;&nbsp;';
              break;
            }

            this._renderer.setProperty(this.loginTextEl.nativeElement, 'innerHTML', newText);

            if (this.loginTextEl.nativeElement.innerHTML === 'Logging in to Finesse&nbsp;&nbsp;&nbsp;') {
              this.dotsGoingUp = true;
            }
          }
        }, 400);
      } else {
        this._loggerService.logger.logInformation('FINESSE - Home Component - toggleLoginLoad : Stopping animation');

        this._renderer.setStyle(this.loginTextEl.nativeElement, 'display', 'none');
        this._renderer.removeStyle(this.finesseContainerEl.nativeElement, 'filter');
        this._renderer.removeStyle(this.finesseContainerEl.nativeElement, '-webkit-filter');

        clearInterval(this.loginAnimationInterval);
        this.loginAnimationInterval = null;

        this.ngAfterViewChecked();
      }

      this._loggerService.logger.logInformation('FINESSE - Home Component - toggleLoginLoad : END');
    } catch (e) {
      this._loggerService.logger.logError(`FINESSE - Home Component - toggleLoginLoad - Error : ${JSON.stringify(e)}`);
    }
  }
}
