import { IClientConfiguration } from './../models/IClientConfiguration';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, LOG_SOURCE, LOG_LEVEL, ERROR_CODE, getDaVinciAgentConfig } from '@amc-technology/davinci-api';
import { ILoggerConfiguration, defaultLoggerConfiguration } from '@amc-technology/davinci-api/dist/models/LoggerConfiguration';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  public logger: Logger;
  public frameworkConfig: any;
  private _configUrlPath = 'ClientConfiguration';
  private localLog = false;
  private _clientConfig: any;

  constructor(private _http: HttpClient) {
  }

  public async initializeLogger() {
    try {
      this._clientConfig = await this._http.get<IClientConfiguration>(this._configUrlPath).toPromise();

      // Get raw config. Framework does not perform data validation intentionally
      const rawConfig = await getDaVinciAgentConfig();
      const loggerConfig = this.parseLoggerConfig(rawConfig);

      this.logger = new Logger(
        LOG_SOURCE.Finesse,
        false,
        this._clientConfig.loggerApiUrl
      );
      this.logger.setConfiguration(loggerConfig);
    } catch (err) {
      this.handleLoggerInitializationError(err);
    }
  }
  /**
   * Logs a message to the loggerApi
   *
   * @param {LOG_LEVEL} logLevel - The level of the log
   * @param {string} fName - Name of the function logging the message
   * @param {string} message - Message to log
   * @param {*} [object] - Optional object to log
   * @param {ERROR_CODE} [errorCode] - Optional error code
   * @param {Date} [localTime] - Optional local time
   * @memberof LoggerService
   */
  async log(logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) {
    const functionName = 'log';
    try {
      if (this.localLog) {
        this.localLogMessage(logLevel, fName, message, object, errorCode, localTime);
      }
      switch (logLevel) {
      case LOG_LEVEL.Loop:
        this.logger.logLoop(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ''), errorCode, localTime);
        break;
      case LOG_LEVEL.Trace:
        this.logger.logTrace(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ''), errorCode, localTime);
        break;
      case LOG_LEVEL.Information:
        this.logger.logInformation(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ''), errorCode, localTime);
        break;
      case LOG_LEVEL.Warning:
        this.logger.logWarning(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ''), errorCode, localTime);
        break;
      case LOG_LEVEL.Error:
        this.logger.logError(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ''), errorCode, localTime);
        break;
      case LOG_LEVEL.Critical:
        this.logger.logCritical(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ''), errorCode, localTime);
        break;
      case LOG_LEVEL.Debug:
        this.logger.logDebug(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ''), errorCode, localTime);
        break;
      default:
        this.logger.logInformation(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ''), errorCode, localTime);
        break;
      }
    } catch (e) {
      this.logger.logError(`${functionName} - Failed to log ${logLevel} from ${fName}. Error: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      console.log(`${functionName} - Failed to log ${logLevel} from ${fName}. Error: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  private handleLoggerInitializationError(error: any) {
    try {
      this.logger = new Logger(
        LOG_SOURCE.Finesse,
        false,
        this._clientConfig.loggerApiUrl
      );

      this.logger.logCritical('loggerService.initializeLogger(): Error creating logger!', error);
    } catch (nestedError) {
      try {
        this.logger = new Logger(
          LOG_SOURCE.Finesse,
          true
        );
        this.logger.logCritical('loggerService.initializeLogger(): Error creating logger!', nestedError);
      } catch (finalError) {
        console.error('Logger initialization error:', finalError);
      }
    }
  }

  private parseLoggerConfig(rawConfig: any): ILoggerConfiguration {
    let logLevel = parseInt(
      rawConfig?.variables?.['Log Level']?.toString(),
      10
    );

    logLevel = isNaN(logLevel)
      ? defaultLoggerConfiguration['Log Level']
      : logLevel;

    let maxLength = parseInt(
      rawConfig?.['Console Logger']?.variables?.['Max Length']?.toString(),
      10
    );

    maxLength = isNaN(maxLength)
      ? defaultLoggerConfiguration['Console Logger']['Max Length']
      : maxLength;


    const loggerType = rawConfig?.variables?.['Logger Type']?.toString() || defaultLoggerConfiguration['Logger Type'];
    const premiseLoggerURL = rawConfig?.variables?.['Premise Logger URL']?.toString() || defaultLoggerConfiguration['Premise Logger URL'];

    return {
      'Log Level': logLevel,
      'Logger Type': loggerType,
      'Premise Logger URL': premiseLoggerURL,
      'Console Logger': { 'Max Length': maxLength }
    };
  }

  /**
   * This function logs a message to the console that is color coded based off of its LOG_LEVEL
   *
   * @private
   * @param {LOG_LEVEL} logLevel
   * @param {string} fName
   * @param {string} message
   * @param {*} [object]
   * @param {ERROR_CODE} [errorCode]
   * @param {Date} [localTime]
   * @memberof LoggerService
   */
  private localLogMessage(logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) {
    const functionName = 'localLogMessage';
    try {
      let backgroundColor = '#003300';
      let textColor = '#00ff00';

      switch (logLevel) {
      case LOG_LEVEL.Loop:
      case LOG_LEVEL.Trace:
        backgroundColor = '#000033';
        textColor = '#0077ff';
        break;
      case LOG_LEVEL.Information:
      case LOG_LEVEL.Debug:
        backgroundColor = '#003300';
        textColor = '#00ff00';
        break;
      case LOG_LEVEL.Warning:
      case LOG_LEVEL.Error:
      case LOG_LEVEL.Critical:
        backgroundColor = '#330000';
        textColor = '#ff0000';
        break;
      default:
        backgroundColor = '#003300';
        textColor = '#00ff00';
        break;
      }
      const fontWeight = 'bold';
      console.log(
        `%c${fName} | ${LOG_LEVEL[logLevel]} | ${message}${object && Object.keys(object).length > 0 ? `\n\n${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ''}`,
        `background: ${backgroundColor}; color: ${textColor}; font-weight: ${fontWeight};`
      );
    } catch (e) {
      console.log(`${functionName} - Failed to log ${logLevel} from ${fName}. Error: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  private stringifyReplacer() {
    const ancestors = [];
    return function (key, value) {
      if (typeof value !== 'object' || value === null) {
        if (value instanceof Set) {
          return [...value];
        }
        if (key === 'promise') {
          return {};
        }
        return value;
      } else {
        while (ancestors.length > 0 && ancestors[ancestors.length - 1] !== this) {
          ancestors.pop();
        }
        if (ancestors.includes(value)) {
          return '[Circular]';
        }
        ancestors.push(value);
        return value;
      }
    };
  }

}
