import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { Device } from '../models/IDevice';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _username: string;
  private _password: string;
  private _domain: string;
  private _extension: string;
  private _credentials: string;
  private _device: Device;

  constructor(private _loggerService: LoggerService) { }

  // Getters
  public get username() {
    return this._username;
  }

  public get password() {
    return this._password;
  }

  public get domain() {
    return this._domain;
  }

  public get extension() {
    return this._extension;
  }

  public get credentials(): string {
    return this._credentials;
  }
  public get device(): Device {
    return this._device;
  }

  // Setters
  public set username(username: string) {
    this._username = username;
  }

  public set password(password: string) {
    this._password = password;
  }

  public set domain(domain: string) {
    this._domain = domain;
  }

  public set extension(extension: string) {
    this._extension = extension;
  }

  public set device(device: Device) {
    this._device = device;
  }

  public setCredentials() {
    try {
      this._loggerService.logger.logInformation('FINESSE - UserService - setCredentials - START');

      this._credentials = btoa(`${this._username}:${this._password}`);

      this._loggerService.logger.logInformation('FINESSE - UserService - setCredentials - END');
    } catch (error) {
      this._loggerService.logger.logDebug(`FINESSE - UserService - setCredentials - Error: ${error.message || error}`);
    }
  }

  public getJid(): string {
    return `${this._username}@${this._domain}`;
  }
}




