import { UserService } from './user.service';
import { ConfigurationService } from './finesse-configuration.service';
import { Injectable } from '@angular/core';
import {
  IInteraction,
  IMetadata,
  IOperation,
  IParty,
  IScenario,
} from '@amc-technology/ui-library';
import { FinesseService } from './finesse.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class UiOperationsService {
  private _iconPack: string;
  private _finesse: FinesseService;
  private _callControls: {
    [index: string]: boolean;
  };

  constructor(
    private _config: ConfigurationService,
    private _user: UserService,
    private _loggerService: LoggerService
  ) {}

  public constructUIInteraction(callData: any): IScenario {
    try {
      this._loggerService.logger.logInformation('FINESSE - UiOperationsService - constructUIInteraction - START');

      let agent: any;
      let startTime = new Date();
      const metadata: IMetadata[] = [];
      const isInbound = this._finesse.interactionTypeList[callData.id.toString()] !== 'OUTBOUND';
      let customerNumber = '';

      if (isInbound && this._finesse.areAllParticipantsAgents(callData.participants.Participant)) {
        customerNumber = callData.fromAddress.toString();
      } else {
        for (const callVariable of callData.mediaProperties.callvariables
          .CallVariable) {
          if (callVariable.name === this._finesse.customerNumberCADECCName) {
            customerNumber = callVariable.value.toString();
          }
        }
      }

      if (Array.isArray(callData.participants.Participant)) {
        for (const participant of callData.participants.Participant) {
          if (participant.mediaAddress.toString(10) === this._user.extension) {
            agent = participant;

            break;
          }
        }
      } else {
        if (
          callData.participants.Participant['mediaAddress'].toString(10) ===
          this._user.extension
        ) {
          agent = callData.participants.Participant;
        }
      }

      if (agent.startTime) {
        startTime = new Date(agent.startTime);
      }

      metadata.push({
        key: 'callId',
        value: callData.id.toString(10),
      });

      if (agent.state && agent.state !== 'DROPPED' && agent.state !== 'WRAP_UP') {
        const interactions = this._finesse.callSubject.getValue()
          .interactions.filter((i) => i.interactionId !== callData.id);

        const interaction: IInteraction = {
          interactionId: callData.id,
          startTime: startTime.getTime(),
          displayCallTimer: true,
          subheaderData: {
            image: new URL(this._iconPack + 'Phone_Number_Icon.png'),
            tooltip: 'Phone',
            value: callData.fromAddress,
          },
          UIHeadersData: {
            maximizeUrl: new URL(this._iconPack + 'section_expand.png'),
            minimizeUrl: new URL(this._iconPack + 'section_collapse.png'),
            directionText: isInbound ? 'Inbound' : 'Outbound',
            displayHoldCounter: false,
            statusText: 'Ringing',
            statusUrl: new URL(this._iconPack + 'Status_Ringing.png'),
          },
          operations: this.getOperations(agent.state, {
            isInbound: isInbound,
          }),
        };

        if (!isInbound) {
          interaction.subheaderData.value = customerNumber || callData.toAddress;
        } else {
          interaction.subheaderData.value = customerNumber || callData.fromAddress;
        }


        // Specifically addresses incoming internal calls showing "Inbound" until accepted
        if (
          callData.mediaProperties.callType === 'OUT' &&
          isInbound &&
          this._finesse.areAllParticipantsAgents(callData.participants.Participant)
        ) {
          interaction.UIHeadersData.directionText = 'Consult Inbound';
        }

        if (
          callData.mediaProperties.callType === 'CONSULT' &&
          !isInbound
        ) {
          interaction.UIHeadersData.directionText = 'Consult Outbound';

          const scenarioList = this._finesse.scenarioList;
          const interactionIdToScenarioID = this._finesse.interactionIdToScenarioID;
          const scenarioID = interactionIdToScenarioID[callData.id];

          let associatedCallId = '';

          for (const key of Object.keys(scenarioList[scenarioID])) {
            if (key.toString() !== callData.id.toString()) {
              associatedCallId = key;
              break;
            }
          }

          if (associatedCallId) {
            metadata.push({
              key: 'associatedCallId',
              value: associatedCallId,
            });
          }

          if (callData.toAddress) {
            interaction.subheaderData.value = callData.toAddress.toString(10);
            metadata.push({
              key: 'toAddress',
              value: callData.toAddress.toString(10),
            });
          }

          if (agent.state === 'INITIATING' || agent.state === 'INITIATED' || agent.state === 'ALERTING') {
            interaction.operations = this.getOperations('CONSULT_ALERTING');
          } else if (agent.state === 'HELD') {
            interaction.operations = this.getOperations('CONSULT_HELD');
            interaction.UIHeadersData.statusUrl = new URL(
              this._iconPack + 'Status_OnHold.png'
            );
            interaction.UIHeadersData.statusText = 'On Hold';
          } else {
            interaction.operations = this.getOperations('CONSULT_CONNECTED', {
              fromAddress: callData.fromAddress.toString(10),
            });
            interaction.UIHeadersData.statusText = 'Connected';
            interaction.UIHeadersData.statusUrl = new URL(
              this._iconPack + 'Status_OnCall.png'
            );
          }
        } else if (
          callData.mediaProperties.callType === 'CONSULT' &&
          isInbound
        ) {
          interaction.UIHeadersData.directionText = 'Consult Inbound';
          if (callData.fromAddress) {
            interaction.subheaderData.value = callData.fromAddress.toString();
          }
        }


        if (agent.state === 'ACTIVE') {
          interaction.UIHeadersData.statusText = 'Connected';
          interaction.UIHeadersData.statusUrl = new URL(
            this._iconPack + 'Status_OnCall.png'
          );
        } else if (agent.state === 'HELD') {
          interaction.UIHeadersData.statusUrl = new URL(
            this._iconPack + 'Status_OnHold.png'
          );
          interaction.UIHeadersData.statusText = 'On Hold';
        }

        if (callData.mediaProperties.callType === 'CONFERENCE') {
          const parties: IParty[] = [];
          const partyDeviceType = [];

          interaction.UIHeadersData.directionText = 'Conference';

          for (const participant of callData.participants.Participant) {
            if (
              participant.mediaAddress.toString(10) !== this._user.extension && participant
            ) {
              let dropParticipant = false;
              if (agent.actions && agent.actions.action && agent.actions.action.indexOf('PARTICIPANT_DROP') > - 1) {
                if (participant.mediaAddressType === 'AGENT_DEVICE') {
                  dropParticipant = true;
                }
              }
              let showParticipant = false;
              if (participant.state === 'ACTIVE' || participant.state === 'HELD') {
                showParticipant = true;
              }
              if (showParticipant) {
                partyDeviceType.push(participant.mediaAddressType);
                parties.push({
                  header: {
                    image: new URL(this._iconPack + 'Phone_Number_Icon.png'),
                    tooltip: 'Phone',
                    value: participant.mediaAddress,
                  },
                  operations: dropParticipant ?  this.getOperations('CONFERENCE_PARTICIPANT', {
                    partyAddress: participant.mediaAddress.toString(10),
                    callId: callData.id.toString(10),
                  }) : [],
                  properties: [],
                });
              }
            }
          }

          interaction.parties = parties;

          if (interaction.parties.length === 1) {
            let callType = '';
            interaction.subheaderData.value = parties[0].header.value;

            if (partyDeviceType[0] === 'AGENT_DEVICE') {
              callType = 'Internal';
            }
            if (agent.mediaAddress.toString() === callData.fromAddress.toString() ) {
              callType += (callData ? ' ' : '') + 'Outbound';
            } else {
              callType += (callData ? ' ' : '') + 'Inbound';
            }
            interaction.UIHeadersData.directionText = callType;
          }
        } else if (callData.mediaProperties.callType === 'AGENT_INSIDE') {
          if (isInbound) {
            interaction.UIHeadersData.directionText = 'Consult Inbound';
          } else {
            interaction.UIHeadersData.directionText = 'Consult Outbound';
          }
        }

        for (const operation of interaction.operations) {
          operation.operationMetadata = metadata;
        }

        interactions.push(interaction);
        this._loggerService.logger.logInformation('FINESSE - UiOperationsService - constructUIInteraction - END');

        return { interactions: interactions };
      } else if (agent.state === 'DROPPED') {
        if (this._finesse.doWrapupCodesExist) {
          const updatedDispositions = this._finesse.removeDispositionFromList(
            callData.id.toString(10)
          );
          this._finesse.dispositionSubject.next(updatedDispositions);
        }

        this._finesse.removeInteractionDetails(callData.id);
        const interactions = this._finesse.callSubject.getValue()
          .interactions.filter(
            (interaction) => interaction.interactionId !== callData.id
          );

        this._loggerService.logger.logInformation('FINESSE - UiOperationsService - constructUIInteraction - END');

        return { interactions: interactions };
      } else if (agent.state === 'WRAP_UP') {
        const interactions = this._finesse.callSubject.getValue()
          .interactions.filter(
            (interaction) => interaction.interactionId !== callData.id
          );

        if (this._finesse.doWrapupCodesExist) {
          this._finesse.updateDispositions(callData);
        }

        this._loggerService.logger.logInformation('FINESSE - UiOperationsService - constructUIInteraction - END');

        return { interactions: interactions };
      } else {
        this._loggerService.logger.logInformation('FINESSE - UiOperationsService - constructUIInteraction - END');

        return this._finesse.callSubject.getValue();
      }
    } catch (error) {
      this._loggerService.logger.logDebug(`FINESSE - UiOperationsService - constructUIInteraction - Error: ${error.message || error}`);
    }
  }

  public setFinesseService(finesse: FinesseService) {
    this._finesse = finesse;
  }

  public async initialize() {
    this.initVariables();
  }

  private initVariables() {
    try {
      this._loggerService.logger.logInformation('FINESSE - UiOperationsService - initVariables - START');

      // TODO: This should not be hard coded, read from config
      this._iconPack = 'https://amcdevstorage.blob.core.windows.net/icon-pack/';
      this._callControls = {
        showConference: this._config.getValue('CallControls', 'variables', 'showConference') || false,
        showHangupDuringHold: this._config.getValue('CallControls', 'variables', 'showHangupDuringHold') || false,
        showHold: this._config.getValue('CallControls', 'variables', 'showHold') || false,
        showWarmTransfer: this._config.getValue('CallControls', 'variables', 'showWarmTransfer') || false,
        showBlindTransfer: this._config.getValue('CallControls', 'variables', 'showBlindTransfer') || false,
        showDropPartyDuringConference: this._config.getValue('CallControls', 'variables', 'showDropPartyDuringConference') || false,
        showBlindTransferForOutbound: this._config.getValue('CallControls', 'variables', 'showBlindTransferForOutbound') || false,
      };

      this._loggerService.logger.logInformation('FINESSE - UiOperationsService - initVariables - END');
    } catch (error) {
      this._loggerService.logger.logDebug(`FINESSE - UiOperationsService - initVariables - Error : ${error.message || error}`);
    }
  }

  private createAnswerCallOperation(): IOperation {
    const operation: IOperation = {
      operationName: 'answer_call',
      icon: new URL(this._iconPack + 'voice_alerting_answer_normal.gif'),
      title: 'Answer Call',
      handler: this._finesse.answerCall,
    };

    return operation;
  }

  private createEndCallOperation(): IOperation {
    const operation: IOperation = {
      operationName: 'end_call',
      icon: new URL(this._iconPack + 'voice_end_normal.png'),
      title: 'End Call',
      handler: this._finesse.endCall,
    };

    return operation;
  }

  private createHoldCallOperation(): IOperation {
    const operation: IOperation = {
      operationName: 'hold_call',
      icon: new URL(this._iconPack + 'voice_hold_normal.png'),
      title: 'Hold Call',
      handler: this._finesse.holdCall,
    };

    return operation;
  }

  private createUnholdCallOperation(): IOperation {
    const operation: IOperation = {
      operationName: 'unhold_call',
      icon: new URL(this._iconPack + 'voice_unhold_normal.png'),
      title: 'Retrieve Call',
      handler: this._finesse.unholdCall,
    };

    return operation;
  }

  private createBlindTransferOperation(): IOperation {
    const operation: IOperation = {
      operationName: 'blind_transfer',
      icon: new URL(this._iconPack + 'voice_blindtransfer_normal.png'),
      title: 'Blind Transfer',
      handler: this._finesse.blindTransfer,
    };

    return operation;
  }

  private createWarmTransferOperation(): IOperation {
    const operation: IOperation = {
      operationName: 'warm_transfer',
      icon: new URL(this._iconPack + 'voice_warmtransfer_normal.png'),
      title: 'Warm Transfer',
      handler: this._finesse.warmTransfer,
    };

    return operation;
  }

  private createConferenceOperation(): IOperation {
    const operation: IOperation = {
      operationName: 'conference_consult',
      icon: new URL(this._iconPack + 'voice_conference_normal.png'),
      title: 'Consult Conference',
      handler: this._finesse.conferenceCall,
    };

    return operation;
  }

  private createMergeCallOperation(): IOperation {
    const operation: IOperation = {
      operationName: 'merge_call',
      icon: new URL(this._iconPack + 'accept_work.png'),
      title: 'Merge Call',
      handler: this._finesse.mergeCall,
    };

    return operation;
  }

  private createDropFromConferenceOperation(partyAddress: string, callId: string): IOperation {
    const operation: IOperation = {
      operationName: 'drop_from_conference',
      icon: new URL(this._iconPack + 'voice_end_normal.png'),
      title: 'Drop From Conference',
      operationMetadata: [
        {
          key: 'partyAddress',
          value: partyAddress,
        },
        {
          key: 'callId',
          value: callId,
        },
      ],
      handler: this._finesse.dropFromConference,
    };

    return operation;
  }

  private getOperations(state: string, associatedData?: any): IOperation[] {
    try {
      this._loggerService.logger.logInformation('FINESSE - UiOperationsService - getOperations - START');

      const operations: IOperation[] = [];

      switch (state) {
      case 'ALERTING': {
        operations.push(this.createAnswerCallOperation());

        break;
      }
      case 'ACTIVE': {
        operations.push(this.createEndCallOperation());

        if (this._callControls.showHold) {
          operations.push(this.createHoldCallOperation());
        }

        if (associatedData.isInbound === true) {
          if (this._callControls.showBlindTransfer) {
            operations.push(this.createBlindTransferOperation());
          }
        } else if (associatedData.isInbound === false) {
          if (this._callControls.showBlindTransferForOutbound) {
            operations.push(this.createBlindTransferOperation());
          }
        }

        if (this._callControls.showWarmTransfer) {
          operations.push(this.createWarmTransferOperation());
        }

        if (this._callControls.showConference) {
          operations.push(this.createConferenceOperation());
        }

        break;
      }
      case 'HELD': {
        if (this._callControls.showHangupDuringHold) {
          operations.push(this.createEndCallOperation());
        }

        operations.push(this.createUnholdCallOperation());

        break;
      }
      case 'CONSULT_ALERTING': {
        operations.push(this.createEndCallOperation());

        break;
      }
      case 'CONSULT_HELD': {
        if (this._callControls.showHangupDuringHold) {
          operations.push(this.createEndCallOperation());
        }

        operations.push(this.createUnholdCallOperation());

        break;
      }
      case 'CONSULT_CONNECTED': {
        operations.push(this.createEndCallOperation());

        if (this._callControls.showHold) {
          operations.push(this.createHoldCallOperation());
        }

        if (
          associatedData.fromAddress.toString(10) === this._user.extension
        ) {
          operations.push(this.createMergeCallOperation());
        }

        break;
      }
      case 'INITIATING': {
        operations.push(this.createEndCallOperation());

        break;
      }
      case 'INITIATED': {
        operations.push(this.createEndCallOperation());

        break;
      }
      case 'CONFERENCE_PARTICIPANT': {
        if (associatedData.partyAddress && associatedData.callId) {
          if (this._callControls.showDropPartyDuringConference) {
            operations.push(this.createDropFromConferenceOperation(associatedData.partyAddress, associatedData.callId));
          }
        }

        break;
      }
      }
      this._loggerService.logger.logInformation('FINESSE - UiOperationsService - getOperations - END');

      return operations;
    } catch (error) {
      this._loggerService.logger.logDebug(`FINESSE - UiOperationsService - getOperations - Error: ${error.message || error}`);
    }
  }

}
