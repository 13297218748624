import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { UILibraryModule } from '@amc-technology/ui-library';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home-finesse.component';
import { LoggerService } from './services/logger.service';
import { DeviceSelectionComponent } from './device-selection/device-selection.component';

export function initLogger(loggerService: LoggerService) {
  return () => loggerService.initializeLogger();
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DeviceSelectionComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    UILibraryModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
    ])
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initLogger,
      deps: [LoggerService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
