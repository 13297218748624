export class GlobalConstants {
  public static readonly PWD_KEY: string = 'DaVinci.Finnesse.User.PWD';
  public static readonly DEVICE_KEY: string = 'DaVinci.Finnesse.User.Device';
  public static readonly USERNAME_KEY: string = 'DaVinci.Finnesse.User.Username';
  public static readonly EXTENSION_KEY: string = 'DaVinci.Finnesse.User.Extension';
  public static readonly DOMAIN_KEY: string = 'DaVinci.Finnesse.Domain';
  public static readonly CONNECTION_NAME: string = 'DaVinci.Finnesse.ConnectionName';
  public static readonly INTERACTION_TYPE_LIST: string = 'DaVinci.Finnesse.InteractionTypeList';
  public static readonly OUTBOUND_CALLID_SCENARIOID_MAPPING: string = 'DaVinci.Finnesse.Outbound.CallIdScenarioIdMapping';

  public static readonly USER_ATTRIBUTE_USERNAME: string = 'Username';
  public static readonly USER_ATTRIBUTE_PASSWORD: string = 'Password';
  public static readonly USER_ATTRIBUTE_DOMAIN: string = 'Domain';
  public static readonly USER_ATTRIBUTE_EXTENSION: string = 'Extension';

}
