import { Injectable } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private _config: any;
  private _seenValues: {
    [index: string]: any;
  };

  constructor(private _loggerService: LoggerService) { }

  public async initialize() {
    try {
      this._loggerService.logger.logInformation('FINESSE - ConfigurationService - initialize: START');

      this._config = await api.getConfig();
      this._seenValues = {};

      this._loggerService.logger.logInformation('FINESSE - ConfigurationService - initialize: END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - ConfigurationService - initialize - Error : ${error.message || error}`);
    }
  }

  // TODO: Add logs
  public getValue(...keys): any {
    const queryString = keys.toString();

    if (queryString in this._seenValues) {
      return this._seenValues[queryString];
    } else if (this._config) {
      const finalKey = keys[keys.length - 1];
      keys = keys.slice(0, keys.length - 1);
      let curObj = this._config;

      if (keys.length >= 1) {
        for (const key of keys) {
          if (!curObj[key]) {
            return null;
          }

          curObj = curObj[key];
        }
      }

      if (curObj[finalKey]) {
        this._seenValues[queryString] = curObj[finalKey];

        return curObj[finalKey];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
