import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Device } from '../models/IDevice';

@Component({
  selector: 'app-device-selection',
  templateUrl: './device-selection.component.html',
  styleUrls: ['./device-selection.component.css']
})
export class DeviceSelectionComponent implements OnInit {
  @Input() devices: Device[];
  @Output() deviceSelected = new EventEmitter<Device>();

  @ViewChild('deviceSelection', { static: false }) deviceSelectionEl: ElementRef;
  constructor() { }

  ngOnInit(): void {
  }

  onDeviceSelected(device: Device) {
    this.deviceSelected.emit(device);
  }
}
