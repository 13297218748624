// Jabberwerx docs: https://d1nmyq4gcgsfi5.cloudfront.net/media/AJAX-XMPP-Library-Index/api/
// Cisco Finesse Dev Guide: https://developer.cisco.com/docs/finesse/#!rest-api-dev-guide

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalConstants } from './../common/globalconstants';
import { v4 as uuidv4 } from 'uuid';
import { bind } from 'bind-decorator';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import * as api from '@amc-technology/davinci-api';
import { XMLParser } from 'fast-xml-parser';
import {
  CHANNEL_TYPES,
  CONTEXTUAL_OPERATION_TYPE,
  IContextualContact,
  IField,
  IInteraction,
  INTERACTION_DIRECTION_TYPES,
  INTERACTION_STATES,
  RecordItem,
  NOTIFICATION_TYPE
} from '@amc-technology/davinci-api';
import { UserService } from './user.service';
import { LoggerService } from './logger.service';
import { ConfigurationService } from './finesse-configuration.service';
import { UiOperationsService } from './ui-operations.service';
import { IMetadata, IScenario } from '@amc-technology/ui-library';
import { IDisposition } from './../models/IDisposition.interface';
import { JABBER_CONNECTION_STATUS } from '../models/JabberConnectionStatus.enum';
import { Device } from '../models/IDevice';

declare let jabberwerx: any;

@Injectable({
  providedIn: 'root',
})
export class FinesseService {
  doWrapupCodesExist: boolean;
  loginSubject = new Subject<boolean>();
  callSubject = new BehaviorSubject<IScenario>({ interactions: [] });
  dispositionSubject = new BehaviorSubject<IDisposition[]>([]);
  jabberConnectionStatusSubject = new BehaviorSubject<number>(3);
  deviceSelectionSubject = new BehaviorSubject<Device[]>([]);

  private _ciscoClient: any;
  private _connectionCheckInterval: any;
  private _connectionIntervalTimer: number;
  private _webAppPath: string;
  private _webAppPathURL: any;
  private _longPollingPort: any;
  private _restApiPort: any;
  private _davinciToChannel: any;
  private _channelToDavinci: any;
  private _davinciToChannelLogout: any;
  private _channelToDavinciLogout: any;
  private _interactionDetails: any;
  private _notReadyIdToName: any;
  private _notReadyNameToId: any;
  private _logoutIdToName: any;
  private _logoutNameToId: any;
  private _wrapupNameToId: any;
  private _wrapupIdToName: any;
  private _generatedUniqueIdentifierName: string;
  private _uniqueIdentifierCADNames: any;
  private _useCustomUniqueIdentifier: boolean;
  private _scenarioIdCadName: any;
  private _customerNumberCADECCName: any;
  private _isCallbackECCName: any;
  private _interactionTypeList: any;
  private _scenarioList: any;
  private _interactionIdToScenarioID: any;
  private _isOnInteraction: boolean;
  private _onInteractionPresnece: string;
  private _droppedInteractions: Set<String>;
  private localStorage: any;
  private sessionStorage: any;
  private _scenarioMap: {
    [scenarioId: string]: {
      [interactionId: string]: api.IInteraction;
    };
  } = {};
  private _selfTrigerredJabberDisconnect: boolean;
  private readonly cname = 'FinesseService';
  private _finesse_version: string;
  private _userAttributesCameFromCloud: any;
  private _username: string;
  private _xmlParser = new XMLParser();
  private _currentPresence: string;

  constructor(
    private _user: UserService,
    private _config: ConfigurationService,
    private _operations: UiOperationsService,
    private _http: HttpClient,
    private _loggerService: LoggerService
  ) { }

  public get customerNumberCADECCName(): any {
    return this._customerNumberCADECCName;
  }

  public get scenarioList() {
    return this._scenarioList;
  }

  public get interactionIdToScenarioID() {
    return this._interactionIdToScenarioID;
  }

  public get interactionTypeList() {
    return this._interactionTypeList;
  }

  // TODO: Add HTTP error catching
  @bind
  public async makeCall(contact: IContextualContact) {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - makeCall - START');

      const method = 'POST';
      const url = `${this._webAppPath}/api/User/${this._user.extension}/Dialogs`;
      const xmlData = `<Dialog><requestedAction>MAKE_CALL</requestedAction><toAddress>${contact.displayName}</toAddress><fromAddress>${this._user.extension}</fromAddress></Dialog>`;

      await this.sendRestRequest(method, url, xmlData).toPromise();

      this._loggerService.logger.logInformation('FINESSE - FinesseService - makeCall - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - makeCall - Error: ${error.message || error}`);

      api.sendNotification('There was an error while making a call, please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
    }
  }

  @bind
  public async answerCall(operationName: string, operationMetadata: IMetadata[]) {
    let callId: string;
    let method: string;
    let url: string;
    let xmlData: string;

    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - answerCall - START');

      for (const metadata of operationMetadata) {
        if (metadata.key === 'callId') {
          callId = metadata.value;
        }
      }

      if (callId) {
        method = 'PUT';
        url = `${this._webAppPath}/api/Dialog/${callId}`;
        xmlData = `<Dialog><targetMediaAddress>${this._user.extension}</targetMediaAddress><requestedAction>ANSWER</requestedAction></Dialog>`;

        await this.sendRestRequest(method, url, xmlData).toPromise();
      }

      this._loggerService.logger.logInformation('FINESSE - FinesseService - answerCall - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - answerCall - Error: ${error.message || error}`);

      // Check if is HTTP error
      if (this.hasHttpStatusCode(error)) {
        if (parseInt(error.status, 10) === 404 || parseInt(error.status, 10) === 401) {
          // Dialog no longer exists.
          this._loggerService.logger.logTrace(`FINESSE - FinesseService - answerCall - Trace: ${error.status} - Invalid URL or Call ${callId} does not exist, removing call. Method=${method}, Url=${url}, Data=${xmlData}`);
          await this.clearLostInteraction(callId);
        } else {
          this._loggerService.logger.logError(`FINESSE - FinesseService - answerCall - Trace: Unexpected HTTP error ${error.status}. Method=${method}, Url=${url}, Data=${xmlData}`);

          api.sendNotification('There was an error answering the call, please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
        }
      }
    }
  }

  @bind
  public async endCall(operationName: string, operationMetadata: IMetadata[]) {
    let method: string;
    let url: string;
    let xmlData: string;
    let callId: string;

    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - endCall - START');

      for (const metadata of operationMetadata) {
        if (metadata.key === 'callId') {
          callId = metadata.value;
        }
      }

      if (callId) {
        method = 'PUT';
        url = `${this._webAppPath}/api/Dialog/${callId}`;
        xmlData = `<Dialog><targetMediaAddress>${this._user.extension}</targetMediaAddress>` + '<requestedAction>DROP</requestedAction></Dialog>';

        await this.sendRestRequest(method, url, xmlData).toPromise();
      }

      this._loggerService.logger.logInformation('FINESSE - FinesseService - endCall - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - endCall - Error: ${error.message || error}`);

      // Check if is HTTP error
      if (this.hasHttpStatusCode(error)) {
        if (parseInt(error.status, 10) === 404 || parseInt(error.status, 10) === 401) {
          // Dialog no longer exists
          this._loggerService.logger.logTrace(`FINESSE - FinesseService - endCall - Trace: ${error.status} - Invalid URL or Call ${callId} does not exist, removing call. Method=${method}, Url=${url}, Data=${xmlData}`);

          await this.clearLostInteraction(callId);
        }
      } else {
        this._loggerService.logger.logError(`FINESSE - FinesseService - endCall - Trace: Unexpected HTTP error ${error.status}. Method=${method}, Url=${url}, Data=${xmlData}`);

        api.sendNotification('There was an error ending the call, please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
      }
    }
  }

  @bind
  public async holdCall(operationName: string, operationMetadata: IMetadata[]) {
    let callId: string;
    let method: string;
    let url: string;
    let xmlData: string;

    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - holdCall - START');

      for (const metadata of operationMetadata) {
        if (metadata.key === 'callId') {
          callId = metadata.value;
        }
      }

      if (callId) {
        method = 'PUT';
        url = `${this._webAppPath}/api/Dialog/${callId}`;
        xmlData = `<Dialog><targetMediaAddress>${this._user.extension}</targetMediaAddress>` + '<requestedAction>HOLD</requestedAction></Dialog>';

        await this.sendRestRequest(method, url, xmlData).toPromise();
      }
      this._loggerService.logger.logInformation('FINESSE - FinesseService - holdCall - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - holdCall - Error: ${error.message || error}`);

      // Check if is HTTP error
      if (this.hasHttpStatusCode(error)) {
        if (parseInt(error.status, 10) === 404 || parseInt(error.status, 10) === 401) {
          // Dialog no longer exists.
          this._loggerService.logger.logTrace(`FINESSE - FinesseService - holdCall - Trace: ${error.status} - Invalid URL or Call ${callId} does not exist, removing call. Method=${method}, Url=${url}, Data=${xmlData}`);

          await this.clearLostInteraction(callId);
        } else {
          this._loggerService.logger.logError(`FINESSE - FinesseService - holdCall - Trace: Unexpected HTTP error ${error.status}. Method=${method}, Url=${url}, Data=${xmlData}`);

          api.sendNotification('There was an error holding the call, please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
        }
      }
    }
  }

  @bind
  public async unholdCall(operationName: string, operationMetadata: IMetadata[]) {
    let callId: string;
    let method: string;
    let url: string;
    let xmlData: string;

    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - unholdCall - START');


      for (const metadata of operationMetadata) {
        if (metadata.key === 'callId') {
          callId = metadata.value;
        }
      }

      if (callId) {
        method = 'PUT';
        url = `${this._webAppPath}/api/Dialog/${callId}`;
        xmlData = `<Dialog><targetMediaAddress>${this._user.extension}</targetMediaAddress><requestedAction>RETRIEVE</requestedAction></Dialog>`;

        await this.sendRestRequest(method, url, xmlData).toPromise();
      }

      this._loggerService.logger.logInformation('FINESSE - FinesseService - unholdCall - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - unholdCall - Error: ${error.message || error}`);

      // Check if is HTTP error
      if (this.hasHttpStatusCode(error)) {
        if (parseInt(error.status, 10) === 404 || parseInt(error.status, 10) === 401) {
          // Dialog no longer exists.
          this._loggerService.logger.logTrace(`FINESSE - FinesseService - unholdCall - Trace: ${error.status} - Invalid URL or Call ${callId} does not exist, removing call. Method=${method}, Url=${url}, Data=${xmlData}`);

          await this.clearLostInteraction(callId);
        } else {
          this._loggerService.logger.logError(`FINESSE - FinesseService - unholdCall - Trace: Unexpected HTTP error ${error.status}. Method=${method}, Url=${url}, Data=${xmlData}`);

          api.sendNotification('There was an error unholding the call, please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
        }
      }
    }
  }

  @bind
  public async blindTransfer(operationName: string, operationMetadata: IMetadata[]) {
    let callId: string;
    let method: string;
    let url: string;
    let xmlData: string;

    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - blindTransfer - START');

      const response = await api.contextualOperation(CONTEXTUAL_OPERATION_TYPE.BlindTransfer, CHANNEL_TYPES.Telephony)
        .catch((error) => {
          this._loggerService.logger.logError(`FINESSE - FinesseService - blindTransfer - contextualOperation - Error: ${error.message || error}`);
        });

      if (response) {
        for (const metadata of operationMetadata) {
          if (metadata.key === 'callId') {
            callId = metadata.value;
          }
        }

        if (callId) {
          method = 'PUT';
          url = `${this._webAppPath}/api/Dialog/${callId}`;
          xmlData = `<Dialog><targetMediaAddress>${this._user.extension}</targetMediaAddress><toAddress>${response.displayName}</toAddress><requestedAction>TRANSFER_SST</requestedAction></Dialog>`;

          await this.sendRestRequest(method, url, xmlData).toPromise();
        }
      }
      this._loggerService.logger.logInformation('FINESSE - FinesseService - blindTransfer - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - blindTransfer - Error: ${error.message || error}`);

      // Check if is HTTP error
      if (this.hasHttpStatusCode(error)) {
        if (parseInt(error.status, 10) === 404 || parseInt(error.status, 10) === 401) {
          // Dialog no longer exists.
          this._loggerService.logger.logTrace(`FINESSE - FinesseService - blindTransfer - Trace: ${error.status} - Invalid URL or Call ${callId} does not exist, removing call. Method=${method}, Url=${url}, Data=${xmlData}`);

          await this.clearLostInteraction(callId);
        } else {
          this._loggerService.logger.logError(`FINESSE - FinesseService - blindTransfer - Trace: Unexpected HTTP error ${error.status}. Method=${method}, Url=${url}, Data=${xmlData}`);

          api.sendNotification('There was an error during blind transfer, please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
        }
      }
    }
  }

  // TODO: Make consult call a function called by warm transfer and conference
  @bind
  public async warmTransfer(operationName: string, operationMetadata: IMetadata[]) {
    let callId: string;
    let method: string;
    let url: string;
    let xmlData: string;

    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - warmTransfer - START');

      const response = await api.contextualOperation(CONTEXTUAL_OPERATION_TYPE.WarmTransfer, CHANNEL_TYPES.Telephony)
        .catch((error) => {
          this._loggerService.logger.logError(`FINESSE - FinesseService - warmTransfer - contextualOperation - Error: ${error.message || error}`);
        });

      if (response) {
        for (const metadata of operationMetadata) {
          if (metadata.key === 'callId') {
            callId = metadata.value;
          }
        }

        if (callId) {
          method = 'PUT';
          url = `${this._webAppPath}/api/Dialog/${callId}`;
          xmlData = `<Dialog><targetMediaAddress>${this._user.extension}</targetMediaAddress><toAddress>${response.displayName}</toAddress><requestedAction>CONSULT_CALL</requestedAction></Dialog>`;

          await this.sendRestRequest(method, url, xmlData).toPromise();
        }
      }

      this._loggerService.logger.logInformation('FINESSE - FinesseService - warmTransfer - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - warmTransfer - Error: ${error.message || error}`);

      // Check if is HTTP error
      if (this.hasHttpStatusCode(error)) {
        if (parseInt(error.status, 10) === 404 || parseInt(error.status, 10) === 401) {
          // Dialog no longer exists.
          this._loggerService.logger.logTrace(`FINESSE - FinesseService - warmTransfer - Trace: ${error.status} - Invalid URL or Call ${callId} does not exist, removing call. Method=${method}, Url=${url}, Data=${xmlData}`);

          await this.clearLostInteraction(callId);
        } else {
          this._loggerService.logger.logError(`FINESSE - FinesseService - warmTransfer - Trace: Unexpected HTTP error ${error.status}. Method=${method}, Url=${url}, Data=${xmlData}`);

          api.sendNotification('There was an error during warm transfer, please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
        }
      }
    }
  }

  // TODO: Conference is hacky maybe, how to do it better?
  @bind
  public async conferenceCall(operationName: string, operationMetadata: IMetadata[]) {
    let callId: string;
    let method: string;
    let url: string;
    let xmlData: string;

    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - conferenceCall - START');

      const response = await api.contextualOperation(CONTEXTUAL_OPERATION_TYPE.Conference, CHANNEL_TYPES.Telephony)
        .catch((err) => { });

      if (response) {
        for (const metadata of operationMetadata) {
          if (metadata.key === 'callId') {
            callId = metadata.value;
          }
        }

        if (callId) {
          this._interactionDetails[callId] = {
            isConference: true,
          };

          method = 'PUT';
          url = `${this._webAppPath}/api/Dialog/${callId}`;
          xmlData = `<Dialog><targetMediaAddress>${this._user.extension}</targetMediaAddress><toAddress>${response.displayName}</toAddress><requestedAction>CONSULT_CALL</requestedAction></Dialog>`;

          await this.sendRestRequest(method, url, xmlData).toPromise();
        }
      }
      this._loggerService.logger.logInformation('FINESSE - FinesseService - conferenceCall - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - conferenceCall - Error: ${error.message || error}`);

      // Check if is HTTP error
      if (this.hasHttpStatusCode(error)) {
        if (parseInt(error.status, 10) === 404 || parseInt(error.status, 10) === 401) {
          // Dialog no longer exists.
          this._loggerService.logger.logTrace(`FINESSE - FinesseService - conferenceCall - Trace: ${error.status} - Invalid URL or Call ${callId} does not exist, removing call. Method=${method}, Url=${url}, Data=${xmlData}`);

          await this.clearLostInteraction(callId);
        } else {
          this._loggerService.logger.logError(`FINESSE - FinesseService - conferenceCall - Trace: Unexpected HTTP error ${error.status}. Method=${method}, Url=${url}, Data=${xmlData}`);

          api.sendNotification('There was an error during conference call, please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
        }
      }
    }
  }

  @bind
  public async mergeCall(operationName: string, operationMetadata: IMetadata[]) {
    let callId: string;
    let method: string;
    let url: string;
    let xmlData: string;

    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - mergeCall - START');

      let toAddress: string;
      let isConference = false;

      for (const metadata of operationMetadata) {
        if (metadata.key === 'toAddress') {
          toAddress = metadata.value;
        } else if (metadata.key === 'associatedCallId') {
          callId = metadata.value;
        }
      }

      if (toAddress && callId) {
        method = 'PUT';
        url = `${this._webAppPath}/api/Dialog/${callId}`;

        if (callId in this._interactionDetails) {
          if (
            this._interactionDetails[callId] &&
            this._interactionDetails[callId].isConference
          ) {
            isConference = true;
          }
        }

        if (isConference) {
          xmlData = `<Dialog><targetMediaAddress>${this._user.extension}</targetMediaAddress><requestedAction>CONFERENCE</requestedAction><toAddress>${toAddress}</toAddress></Dialog>`;
        } else {
          xmlData = `<Dialog><targetMediaAddress>${this._user.extension}</targetMediaAddress><requestedAction>TRANSFER</requestedAction><toAddress>${toAddress}</toAddress></Dialog>`;
        }

        await this.sendRestRequest(method, url, xmlData).toPromise();
      }
      this._loggerService.logger.logInformation('FINESSE - FinesseService - mergeCall - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - mergeCall - Error: ${error.message || error}`);

      // Check if is HTTP error
      if (this.hasHttpStatusCode(error)) {
        if (parseInt(error.status, 10) === 404 || parseInt(error.status, 10) === 401) {
          // Dialog no longer exists.
          this._loggerService.logger.logTrace(`FINESSE - FinesseService - mergeCall - Trace: ${error.status} - Invalid URL or Call ${callId} does not exist, removing call. Method=${method}, Url=${url}, Data=${xmlData}`);

          await this.clearLostInteraction(callId);
        } else {
          this._loggerService.logger.logError(`FINESSE - FinesseService - mergeCall - Trace: Unexpected HTTP error ${error.status}. Method=${method}, Url=${url}, Data=${xmlData}`);

          api.sendNotification('There was an error during merging call, please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
        }
      }
    }
  }

  @bind
  public async dropFromConference(operationName: string, operationMetadata: IMetadata[]) {
    let callId: string;
    let method: string;
    let url: string;
    let xmlData: string;

    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - dropFromConference - END');

      let partyAddress: string;

      for (const metadata of operationMetadata) {
        if (metadata.key === 'partyAddress') {
          partyAddress = metadata.value;
        } else if (metadata.key === 'callId') {
          callId = metadata.value;
        }
      }

      if (partyAddress && callId) {
        method = 'PUT';
        url = `${this._webAppPath}/api/Dialog/${callId}`;
        xmlData = `<Dialog><targetMediaAddress>${partyAddress}</targetMediaAddress><requestedAction>PARTICIPANT_DROP/requestedAction></Dialog>`;

        await this.sendRestRequest(method, url, xmlData).toPromise();
      }
      this._loggerService.logger.logInformation('FINESSE - FinesseService - dropFromConference - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - dropFromConference - Error: ${error.message || error}`);

      // Check if is HTTP error
      if (this.hasHttpStatusCode(error)) {
        if (parseInt(error.status, 10) === 404 || parseInt(error.status, 10) === 401) {
          // Dialog no longer exists.
          this._loggerService.logger.logTrace(`FINESSE - FinesseService - dropFromConference - Trace: ${error.status} - Invalid URL or Call ${callId} does not exist, removing call. Method=${method}, Url=${url}, Data=${xmlData}`);

          await this.clearLostInteraction(callId);
        } else {
          this._loggerService.logger.logError(`FINESSE - FinesseService - dropFromConference - Trace: Unexpected HTTP error ${error.status}. Method=${method}, Url=${url}, Data=${xmlData}`);

          api.sendNotification('There was an error while dropping from conference, please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
        }
      }
    }
  }

  @bind
  private onClientConnectError(error: any) {
    const fName = 'onClientConnectError';
    try {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'START : An error occurred causing the client to disconnect. Logging out of Finesse, setting loginSubject to false. Error details: ', error);
      this.cleanup();

      this.loginSubject.next(false);

      api.sendNotification('Could not login to Finesse. Please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - onClientConnectError - END : Set loginSubject to false.');
    } catch (e) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - setLocalStorage - Error: ${e.message || e}`);
    }
  }

  @bind
  private onClientConnectSuccess() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - onClientConnectSuccess - START');

      this._user.setCredentials();

      this._loggerService.logger.logInformation('FINESSE - FinesseService - onClientConnectSuccess - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - onClientConnectSuccess - Error: ${error.message || error}`);
    }
  }

  @bind
  private onClientDisconnected(error) {
    const fName = 'onClientDisconnected';
    try {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Client disconnected', { error });
    } catch (e) {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Failed to handle client disconnected event', e);
    }
  }

  @bind
  private onClientConnected() {
    const fName = 'onClientConnected';
    try {
      this._loggerService.log(api.LOG_LEVEL.Debug, fName, 'Client connected');
    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Failed to handle client connected event', error);
    }
  }

  @bind
  private onClientReconnectCountDownStarted(timer: number) {
    const fName = 'onClientReconnectCountDownStarted';
    try {
      this._loggerService.log(api.LOG_LEVEL.Debug, fName, 'Client reconnect countdown started', { timer });
    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Failed to handle client reconnect countdown started event', error);
    }
  }

  @bind
  private onClientReconnectCanceled() {
    const fName = 'onClientReconnectCanceled';
    try {
      this._loggerService.log(api.LOG_LEVEL.Debug, fName, 'Client reconnect canceled');
    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Failed to handle client reconnect canceled event', error);
    }
  }

  @bind
  private onClientIgPresenceReceived(evt: any) {
    const fName = 'onClientIgPresenceReceived';
    try {
      this._loggerService.log(api.LOG_LEVEL.Debug, fName, 'Presence received', { evt });
    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Failed to handle presence received event', error);
    }
  }

  // TODO: Get agent state from setupFinesse, check if pendingState is "LOGIN", and if it is, retry get state 3 times with intervals.
  // TODO: If still no login, disconnect.
  // TODO: Revisdit re-thrown errors and user notifications
  @bind
  private async onClientLoginSuccess() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - onClientLoginSuccess - START');

      let userInfo: any = await this.getState();
      this._loggerService.logger.logInformation(`FINESSE - FinesseService - onClientLoginSuccess - The state info from finesse is ${userInfo.User.state} and the reason code is ${userInfo.User.reasonCodeId}`);

      if ((userInfo.User.pendingState && userInfo.User.pendingState === 'LOGIN') || userInfo.User.state === 'LOGOUT') {
        this._loggerService.logger.logInformation('FINESSE - FinesseService - onClientLoginSuccess - Pending state is LOGIN, retrying getState');

        let retryCount = 0;
        let hasLoginProcessed = false;

        while (retryCount < 2 && !hasLoginProcessed) {
          await this.timeout(2000);
          userInfo = await this.getState();
          hasLoginProcessed = (userInfo.User.pendingState && userInfo.User.pendingState !== 'LOGIN') || userInfo.User.state !== 'LOGOUT';
          retryCount++;
        }

        if (!hasLoginProcessed) {
          this._loggerService.logger.logError('FINESSE - FinesseService - onClientLoginSuccess - Login has not processed after 3 attempts, disconnecting');

          // TODO: In case of wrong extension, for version 12 we need to take action.
          // if (this._finesse_version === '12') {
          api.sendNotification('Login remained in pending status (The extension might be invalid), please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);


          await this.disconnectJabberwerx(true);

          return;
        }
      }

      if (this._finesse_version === '12' && !userInfo.User.activeDeviceId) {
        this._loggerService.logger.logError('FINESSE - FinesseService - onClientLoginSuccess - No active device found for extension');

        api.sendNotification(`Did not find an active device for EXT ${this._user.extension}, please add or ` +
          'turn on a Cisco device for this extension, or sign into a different extension.',
        NOTIFICATION_TYPE.Error);

        await this.disconnectJabberwerx(true);

        return;
      }

      const userAttributesToUpdate = [];
      let shouldUpdateUserAttribute = false;
      if (this._userAttributesCameFromCloud?.Username && this._userAttributesCameFromCloud?.Username.value !== this._user.username) {
        shouldUpdateUserAttribute = true;
        const deepCopyOfUsername = JSON.parse(JSON.stringify(this._userAttributesCameFromCloud.Username));
        deepCopyOfUsername.value = this._user.username;
        userAttributesToUpdate.push(deepCopyOfUsername);
      }

      if (this._userAttributesCameFromCloud?.Password && this._userAttributesCameFromCloud?.Password.value !== this._user.password) {
        shouldUpdateUserAttribute = true;
        const deepCopyOfPassword = JSON.parse(JSON.stringify(this._userAttributesCameFromCloud.Password));
        deepCopyOfPassword.value = this._user.password;
        userAttributesToUpdate.push(deepCopyOfPassword);
      }

      if (this._userAttributesCameFromCloud?.Domain && this._userAttributesCameFromCloud?.Domain.value !== this._user.domain) {
        shouldUpdateUserAttribute = true;
        const deepCopyOfDomain = JSON.parse(JSON.stringify(this._userAttributesCameFromCloud.Domain));
        deepCopyOfDomain.value = this._user.domain;
        userAttributesToUpdate.push(deepCopyOfDomain);
      }

      if (this._userAttributesCameFromCloud?.Extension && this._userAttributesCameFromCloud?.Extension.value !== this._user.extension) {
        shouldUpdateUserAttribute = true;
        const deepCopyOfExtension = JSON.parse(JSON.stringify(this._userAttributesCameFromCloud.Extension));
        deepCopyOfExtension.value = this._user.extension;
        userAttributesToUpdate.push(deepCopyOfExtension);
      }

      if (shouldUpdateUserAttribute) {
        await api.setUserAttributes(this._username, JSON.stringify(userAttributesToUpdate));
        this._userAttributesCameFromCloud.Username.value = this._user.username;
        this._userAttributesCameFromCloud.Password.value = this._user.password;
        this._userAttributesCameFromCloud.Domain.value = this._user.domain;
        this._userAttributesCameFromCloud.Extension.value = this._user.extension;
        api.sendNotification('Your login credentials have been successfully updated!', api.NOTIFICATION_TYPE.Information);
      }


      this.setLocalStorage();
      await this.setupFinesse();

      await this.setPresenceInFramework(userInfo.User.state, userInfo.User.reasonCodeId);
      this.registerPostLoginJwEvents();
      this.loginSubject.next(true);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - onClientLoginSuccess - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - onClientLoginSuccess - Error: ${error.message || error}`);

      if (!error.rethrown) {
        api.sendNotification('Could not complete Finesse login, please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);

        this.clearLocalStorage();
      }
    }
  }

  // jabberwerx.Client.status_connecting = 1
  // jabberwerx.Client.status_connected = 2
  // jabberwerx.Client.status_disconnected = 3
  // jabberwerx.Client.status_disconnecting = 4
  // jabberwerx.Client.status_reconnecting = 5

  @bind
  private async userLoginStatusChanged(evt: any) {
    const fName = 'userLoginStatusChanged';
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - userLoginStatusChanged - START');
      this._loggerService.log(api.LOG_LEVEL.Debug, fName, 'User login status changed', { evt });

      const userInfo: any = await this.getState();
      this._loggerService.logger.logInformation(`FINESSE - FinesseService - userLoginStatusChanged - The state info from finesse is ${userInfo.User.state} and the reason code is ${userInfo.User.reasonCodeId}`);

      if (evt.data.next === jabberwerx.Client.status_connected) {
        this._loggerService.logger.logDebug('FINESSE - FinesseService - userLoginStatusChanged - Received a connected event from Jabber');

        this.jabberConnectionStatusSubject.next(JABBER_CONNECTION_STATUS.CONNECTED);

        this._user.setCredentials();

        if (userInfo.User.state === 'LOGOUT') {
          this._loggerService.logger.logDebug('FINESSE - FinesseService - userLoginStatusChanged - Agent state is LOGOUT, logging them back in');

          this.login();

        } else {
          this._loggerService.logger.logDebug(`FINESSE - FinesseService - userLoginStatusChanged - Agent state is ${userInfo.User.state}, setting presence`);

          this.onClientLoginSuccess();
        }
      } else if (evt.data.next === jabberwerx.Client.status_disconnected) {
        this._loggerService.logger.logDebug('FINESSE - FinesseService - userLoginStatusChanged - Received a disconnect event from Jabber');

        this.jabberConnectionStatusSubject.next(JABBER_CONNECTION_STATUS.DISCONNECTED);

        // If we triggered the disconnect, we don't want to logout the agent from framework.
        // We want to keep the agent logged in, but log out of Finesse because a critical error occurred.
        // This would prevent agent from being logged out of other apps.
        if (this._selfTrigerredJabberDisconnect === true) {
          this._selfTrigerredJabberDisconnect = false;
        } else if (userInfo.User.state === 'LOGOUT') {
          this._loggerService.logger.logDebug('FINESSE - FinesseService - userLoginStatusChanged - The agent state is logout. Logging out.');

          this.cleanup();
          this.disconnectJabberwerx(false);
          await this._loggerService.logger.pushLogsAsync();
          this.loginSubject.next(false);
          api.logout();
        } else {
          this._loggerService.logger.logDebug('FINESSE - FinesseService - userLoginStatusChanged - The agent state is NOT logout. Reconnecting');

          this.connectJabberwerx();
        }
      } else if (evt.data.next === jabberwerx.Client.status_reconnecting) {
        this.jabberConnectionStatusSubject.next(JABBER_CONNECTION_STATUS.RECONNECTING);
      } else if (evt.data.next === jabberwerx.Client.status_connecting) {
        this.jabberConnectionStatusSubject.next(JABBER_CONNECTION_STATUS.CONNECTING);
      } else if (evt.data.next === jabberwerx.Client.status_disconnecting) {
        this.jabberConnectionStatusSubject.next(JABBER_CONNECTION_STATUS.DISCONNECTING);
      }

      this._loggerService.logger.logInformation('FINESSE - FinesseService - userLoginStatusChanged - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - userLoginStatusChanged - Error: ${error.message || error}`);
    }
  }

  @bind
  private failureHandler(data?: any, statusText?: any, xhr?: any) {
    const fName = 'failureHandler';
    try {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Encountered an error while trying to login', { data, statusText, xhr });
      api.sendNotification('There was an error logging into Finesse. \
      Please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);

      this.disconnectJabberwerx(true);
    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Failed to handle failure event', error);
    }
  }

  // TODO: Return true for handled events
  @bind
  private async eventHandler(data: any) {
    const fName = 'eventHandler';
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - eventHandler - START');
      this._loggerService.log(api.LOG_LEVEL.Debug, fName, 'Received event from Finesse', { data });

      setTimeout(() => {
        if (data) {
          let selectedData = data.selected.firstChild.data;
          selectedData = this._xmlParser.parse(selectedData);
          selectedData = selectedData.Update.data;
          this.handleDialog(selectedData);
        }
      }, 0);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - eventHandler - END');
    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Failed to handle event from Finesse.', error);
    }
  }

  @bind
  private async setPresenceInFramework(presence: string, reasonCodeId: number) {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - setPresenceInFramework - START');
      this._loggerService.logger.logInformation(`FINESSE - FinesseService - setPresenceInFramework - The presence passed to this function is: ${presence} and the reason code id is: ${reasonCodeId}`);
      let presenceString: string;
      const reason = this._notReadyIdToName[reasonCodeId];

      if (reason) {
        presenceString = `${presence}|${reason}`;
      } else {
        presenceString = presence;
      }

      if (this._isOnInteraction) {
        presenceString = `Pending ${presenceString}`;
      }

      // TODO: Need to trim trailing and leading whitespaces
      if (!(presenceString in this._channelToDavinci)) {
        const parts = presenceString.split('|');
        presenceString = parts[0];
      }
      if (presenceString in this._channelToDavinci) {
        const currentPresence = await api.getPresence();

        const davinciPresenceString =
          this._channelToDavinci[presenceString].split('|');
        let davinciReason = '';

        if (davinciPresenceString.length > 1) {
          davinciReason = davinciPresenceString[1];
        }

        const davinciPresence = davinciPresenceString[0];
        if (davinciReason) {
          if (
            currentPresence.presence !== davinciPresence ||
            currentPresence.reason !== davinciReason
          ) {
            this._loggerService.log(api.LOG_LEVEL.Debug, 'setPresenceInFramework', 'Setting presence and reason in framework', { davinciPresence, davinciReason });
            await api.setPresence(davinciPresence, davinciReason);
          }
        } else {
          if (currentPresence.presence !== davinciPresence) {
            this._loggerService.log(api.LOG_LEVEL.Debug, 'setPresenceInFramework', 'Setting presence in framework', { davinciPresence });
            await api.setPresence(davinciPresence);
          }
        }
      } else {
        this._loggerService.logger.logDebug(`FINESSE - setPresenceInFramework: The presence code coming from Finesse is ${reasonCodeId}. However it does not configured in Finesse App Configuration!`);
        api.sendNotification(`The presence code coming from Finesse is ${reasonCodeId}. However it does not configured in Finesse App Configuration!`, NOTIFICATION_TYPE.Alert);
      }
      this._loggerService.logger.logInformation('FINESSE - FinesseService - setPresenceInFramework - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - setPresenceInFramework - Error: ${error.message || error}`);
    }
  }

  @bind
  private async setPresenceInFinesse(presence: string, reason?: string, initiatingApp?: string) {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - setPresenceInFinesse - START');
      if (initiatingApp !== this._config.getValue('variables', 'title')) {
        this._loggerService.logger.logDebug(`FINESSE - FinesseService - setPresenceInFinesse - The finesse presence is ${presence}, the reason is ${reason}, and the initiating app is ${initiatingApp}`);
        // Either ready or not ready. For not ready, reason must be present
        let ctiPresenceString: string;

        if (reason) {
          ctiPresenceString = `${presence}|${reason}`;
        } else {
          ctiPresenceString = presence;
        }

        if (ctiPresenceString in this._davinciToChannel) {
          ctiPresenceString =
            this._davinciToChannel[ctiPresenceString].split('|');
          let ctiReason = '';

          if (ctiPresenceString.length > 1) {
            if (presence === 'Not Ready' || presence === 'Pending Not Ready') {
              ctiReason = this._notReadyNameToId[ctiPresenceString[1]];
            }
          }

          const CTI_PRESENCE = ctiPresenceString[0];

          if (this._currentPresence != null && CTI_PRESENCE === this._currentPresence) {
            this._loggerService.logger.logTrace('FINESSE - FinesseService - setPresenceInFinesse - The finesse presence is already set to the correct presence value, ignoring the request');
            return;
          } else {
            this._currentPresence = CTI_PRESENCE;
            // TODO: Refactor this
            if (presence.startsWith('Pending')) {
              const resp = await this.setState(CTI_PRESENCE, ctiReason);

              if (resp.ok) {
                await api.setPresence(presence, reason);
              }

              return;
            }

            const response = await this.setState(CTI_PRESENCE, ctiReason);

            if (response.ok) {
              const userInfo: any = await this.getState();
              this._loggerService.logger.logInformation(`FINESSE - FinesseService - setPresenceInFinesse - The state info from finesse is ${userInfo.User.state} and the reason code is ${userInfo.User.reasonCodeId}`);
              await this.setPresenceInFramework(userInfo.User.state, userInfo.User.reasonCodeId);
            }
          }
        }
      }
      this._loggerService.logger.logInformation('FINESSE - FinesseService - setPresenceInFinesse - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - setPresenceInFinesse - Error: ${error.message || error}`);
    }
  }

  @bind
  private async clickToDial(phoneNumber: string) {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - clickToDial - START');
      this._loggerService.log(api.LOG_LEVEL.Debug, 'clickToDial', 'Click to dial initiated', { phoneNumber });
      const method = 'POST';
      const url = `${this._webAppPath}/api/User/${this._user.extension}/Dialogs`;
      const xmlData = `<Dialog><requestedAction>MAKE_CALL</requestedAction><toAddress>${phoneNumber}</toAddress><fromAddress>${this._user.extension}</fromAddress></Dialog>`;

      await this.sendRestRequest(method, url, xmlData).toPromise();
      this._loggerService.log(api.LOG_LEVEL.Debug, 'clickToDial', 'Click to dial completed', { phoneNumber });
      this._loggerService.logger.logInformation('FINESSE - FinesseService - clickToDial - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - clickToDial - Error: ${error.message || error}`);

      api.sendNotification('There was an error while trying to perform click-to-dial. If the issue persists, okease contact your administrator.', NOTIFICATION_TYPE.Error);
    }
  }

  @bind
  private async login() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - login - START');

      this._user.setCredentials();

      const method = 'PUT';
      const url = `${this._webAppPath}/api/User/${this._user.username}`;
      let xmlData = '';

      if (this._user.device?.id) {
        xmlData = `<User><state>LOGIN</state><extension>${this._user.extension}</extension><activeDeviceId>${this._user.device.id}</activeDeviceId></User>`;
      } else {
        xmlData = `<User><state>LOGIN</state><extension>${this._user.extension}</extension></User>`;
      }

      this.sendRestRequest(method, url, xmlData).subscribe({
        next: this.onClientLoginSuccess,
        error: this.failureHandler,
      });

      this._loggerService.logger.logInformation('FINESSE - FinesseService - login - END');
    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, 'login', 'Failed to login to Finesse.', error);
    }
  }

  @bind
  private async logout(reason?: string) {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - logout');
      this._loggerService.log(api.LOG_LEVEL.Debug, 'logout', 'Logging out of Finesse', { reason });


      if (this._ciscoClient.clientStatus === JABBER_CONNECTION_STATUS.DISCONNECTED ||
        this._ciscoClient.clientStatus === JABBER_CONNECTION_STATUS.DISCONNECTING) {
        this._loggerService.logger.logDebug('FINESSE - FinesseService - logout - Jabber already disconnected, ignoring logout request from DaVinci');

        return;
      }

      if (reason) {
        this._loggerService.logger.logInformation(`FINESSE - FinesseService - logout - Logging out initiated with reason: ${reason}`);
      } else {
        this._loggerService.logger.logInformation('FINESSE - FinesseService - logout - Logging out initiated without reason code');
      }

      let channelReason: string;
      let reasonCodeId: number;

      if (reason) {
        channelReason = this._davinciToChannelLogout[reason] || '';
        reasonCodeId = this._logoutNameToId[channelReason] || null;
      }

      const method = 'PUT';
      const url = `${this._webAppPath}/api/User/${this._user.username}`;
      let xmlData = '';

      if (reasonCodeId) {
        xmlData = `<User><state>LOGOUT</state><reasonCodeId>${reasonCodeId}</reasonCodeId></User>`;
      } else {
        xmlData = '<User><state>LOGOUT</state></User>';
      }

      await this.sendRestRequest(method, url, xmlData).toPromise();

      if (!this._selfTrigerredJabberDisconnect) {
        this.cleanup();
      }

      this.loginSubject.next(false);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - logout - END');

      await this._loggerService.logger.pushLogsAsync();
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - logout - Error: ${error.message || error}`);

      api.sendNotification('There was an error logging out, please try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
    }
  }

  @bind
  private async setCallDisposition(callId: string, dispositionName: string) {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - setCallDisposition - START');
      const method = 'PUT';
      const url = `${this._webAppPath}/api/Dialog/${callId}`;
      const xmlData = `<Dialog><requestedAction>UPDATE_CALL_DATA</requestedAction><mediaProperties><wrapUpReason>${dispositionName}</wrapUpReason></mediaProperties></Dialog>`;

      await this.sendRestRequest(method, url, xmlData).toPromise();

      this._loggerService.logger.logInformation('FINESSE - FinesseService - setCallDisposition - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - setCallDisposition - Error: ${error.message || error}`);

      api.sendNotification('There was an error setting the disposition. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
    }
  }

  public async getUserAttributes() {
    try {
      const userDetails = await api.getUserDetails();
      this._username = userDetails?.username;
      const userAttributes = JSON.parse(userDetails.attributes);
      for (let i = 0; i < userAttributes.length; i++) {
        if (userAttributes[i].name) {
          switch (userAttributes[i].name) {
          case GlobalConstants.USER_ATTRIBUTE_USERNAME:
            this._userAttributesCameFromCloud.Username = userAttributes[i];
            this.localStorage.setItem(GlobalConstants.USERNAME_KEY, btoa(userAttributes[i].value));
            break;
          case GlobalConstants.USER_ATTRIBUTE_PASSWORD:
            this._userAttributesCameFromCloud.Password = userAttributes[i];
            this.localStorage.setItem(GlobalConstants.PWD_KEY, btoa(userAttributes[i].value));
            break;
          case GlobalConstants.USER_ATTRIBUTE_DOMAIN:
            this._userAttributesCameFromCloud.Domain = userAttributes[i];
            this.localStorage.setItem(GlobalConstants.DOMAIN_KEY, btoa(userAttributes[i].value));
            break;
          case GlobalConstants.USER_ATTRIBUTE_EXTENSION:
            this._userAttributesCameFromCloud.Extension = userAttributes[i];
            this.localStorage.setItem(GlobalConstants.EXTENSION_KEY, btoa(userAttributes[i].value));
            break;
          }
        }
      }
    } catch (e) {
      this._loggerService.log(api.LOG_LEVEL.Error, 'getUserAttributes', 'Failed to get user attributes.', e);
    }
  }

  public async setup() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - setup - START');

      await this._config.initialize();
      await this._operations.initialize();
      await this.registerAmcEvents();
      this.initVariables();
      this.registerPreLoginJwEvents();
      this.createConnectionTestInterval();

      await api.initializeComplete(this._loggerService.logger);
      await this.getUserAttributes();

      this._loggerService.logger.logInformation('FINESSE - FinesseService - setup - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - setup - Error: ${error.message || error}`);
    }
  }

  public async connectJabberwerx() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - connectJabberwerx - START');

      const jwArgs = {
        httpBindingURL: `${this._webAppPathURL}:${this._longPollingPort}/http-bind/`,
        errorCallback: this.onClientConnectError,
        successCallback: this.onClientConnectSuccess,
      };
      this._loggerService.log(api.LOG_LEVEL.Debug, 'connectJabberwerx', 'Connecting to Jabberwerx', { userId: this._user.getJid(), jwArgs });

      await this._ciscoClient.connect(this._user.getJid(), this._user.password, jwArgs);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - connectJabberwerx - END');

    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, 'connectJabberwerx', 'Failed to connect to Jabberwerx.', error);
    }
  }

  public updateDispositions(callData: any) {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - updateDisposition - START');

      const existingDispositions = this.dispositionSubject.getValue();
      const checkedDisposition = callData.mediaProperties.wrapUpReason;
      const isInbound =
        callData.mediaProperties.DNIS.toString(10) === this._user.extension
          ? true
          : false;
      let checkedDispositionId = '';

      for (const disposition of existingDispositions) {
        for (const data of disposition.dispositionMetadata) {
          if (
            data.key === 'callId' &&
            data.value === callData.id.toString(10)
          ) {
            return;
          }
        }
      }

      let header = 'Wrapup';
      let customerNumber = '';

      for (const callVariable of callData.mediaProperties.callvariables.CallVariable) {

        if (callVariable.name === this._customerNumberCADECCName) {

          customerNumber = callVariable.value.toString();
        }
      }

      if (customerNumber) {
        header = `Wrapup - ${customerNumber}`;
      } else if (isInbound) {
        header = `Wrapup - ${callData.fromAddress}`;
      } else {
        header = `Wrapup - ${callData.toAddress}`;
      }

      const dispositions: Map<string, string> = new Map();

      for (const wrapup of Object.keys(this._wrapupNameToId)) {
        dispositions.set(`${this._wrapupNameToId[wrapup]}`, wrapup);
      }

      if (checkedDisposition && checkedDisposition in this._wrapupNameToId) {
        checkedDispositionId = this._wrapupNameToId[checkedDisposition];
        header = `${header} - ${checkedDisposition}`;
      }

      const metadata: IMetadata[] = [
        {
          key: 'callId',
          value: callData.id.toString(10),
        },
      ];

      const newDisposition: IDisposition = {
        dispositionHeader: header,
        disposition: dispositions,
        dispositionMetadata: metadata,
        checkedDisposition: checkedDispositionId,
      };

      existingDispositions.push(newDisposition);

      this.dispositionSubject.next(existingDispositions);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - updateDisposition - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - updateDisposition - Error: ${error.message || error}`);
    }
  }

  public receivedDisposition(disposition: any) {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - receivedDisposition - START');

      let callId = '';

      for (const metadata of disposition.dispositionMetadata) {
        if (metadata.key === 'callId') {
          callId = metadata.value;

          break;
        }
      }

      const updatedDispositions = this.removeDispositionFromList(callId);
      this.setCallDisposition(callId, disposition.dispositionName);

      this.dispositionSubject.next(updatedDispositions);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - receivedDisposition - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - receivedDisposition - Error: ${error.message || error}`);
    }
  }

  public removeDispositionFromList(callId: string): IDisposition[] {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - removeDispositionFromList - START');

      const updatedDispositions = this.dispositionSubject.getValue()
        .filter((disp) => {
          for (const metadata of disp.dispositionMetadata) {
            if (metadata.key === 'callId' && metadata.value === callId) {
              return false;
            }
          }

          return true;
        });

      this._loggerService.logger.logInformation('FINESSE - FinesseService - removeDispositionFromList - END');

      return updatedDispositions;
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - removeDispositionFromList - Error: ${error.message || error}`);
    }
  }

  public removeInteractionDetails(callId: string) {
    delete this._interactionDetails[callId];
  }

  public areAllParticipantsAgents(participants: any): boolean {
    const cname = 'FinesseService';
    const fname = 'areAllParticipantsAgents';

    try {
      this._loggerService.logger.logTrace(`FINESSE - ${cname} - ${fname} - START`);

      if (Array.isArray(participants)) {
        for (const participant of participants) {
          if (participant.mediaAddressType !== 'AGENT_DEVICE') {
            this._loggerService.logger.logTrace(`FINESSE - ${cname} - ${fname} - Found non agent, returning false`);

            return false;
          }
        }
      } else {
        if (participants.mediaAddressType !== 'AGENT_DEVICE') {
          this._loggerService.logger.logTrace(`FINESSE - ${cname} - ${fname} - Found non agent, returning false`);

          return false;
        }
      }

      this._loggerService.logger.logTrace(`FINESSE - ${cname} - ${fname} - All participants are agents, returning true`);

      return true;
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - ${cname} - ${fname} - ${JSON.stringify(error)}`);
    } finally {
      this._loggerService.logger.logTrace(`FINESSE - ${cname} - ${fname} - END`);
    }
  }

  public async performDeviceValidation(): Promise<number> {
    try {
      this._loggerService.logger.logDebug('FINESSE - FinesseService - performDeviceValidation - START');

      const finesseDevices = await this.getDevices();

      if (finesseDevices === -1) {
        this._loggerService.logger.logError('FINESSE - FinesseService - performDeviceValidation - Error getting devices from finesse');

        this.cleanup();
        this.loginSubject.next(false);

        return -1;
      }

      let numDevices = 0;
      if (finesseDevices === -2) {
        numDevices = 1;
      } else {
        numDevices = this.getNumberOfDevices(finesseDevices);
      }

      if (numDevices === 0) {
        this._loggerService.logger.logError('FINESSE - FinesseService - performDeviceValidation - No devices found for extension');

        api.sendNotification(`No devices found for EXT ${this._user.extension}, please add or ` +
          'turn on a Cisco device for this extension, or sign into a different extension.',
        NOTIFICATION_TYPE.Error);

        this.cleanup();
        this.loginSubject.next(false);

      } else if (numDevices > 1) {
        this._loggerService.logger.logError('FINESSE - FinesseService - performDeviceValidation - No active device found for extension');

        // Begin device selection
        const userInfo = await this.getState();

        if (userInfo.User?.settings?.deviceSelection === 'enabled') {
          this._loggerService.logger.logDebug('FINESSE - FinesseService - performDeviceValidation - Device selection is enabled, prompting user to select device');

          if (this._user.device) {
            this._loggerService.logger.logDebug('FINESSE - FinesseService - performDeviceValidation - Device is already selected from previous login');

            return 1;
          }

          const devices: Device[] = [];

          for (const device of finesseDevices.Devices.Device) {
            devices.push({
              id: device.deviceId,
              type: device.deviceType,
              typeName: device.deviceTypeName
            });
          }

          this.deviceSelectionSubject.next(devices);
        } else {
          api.sendNotification(`Found ${numDevices} registered devices, but device selection capability is not enabled. Please ensure only one device is registered for this extension.`,
            NOTIFICATION_TYPE.Error);

          this.cleanup();
          this.loginSubject.next(false);
        }
      }

      this._loggerService.logger.logDebug(`FINESSE - FinesseService - performDeviceValidation - Found ${numDevices} devices - END`);

      return numDevices;
    } catch (e) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - performDeviceValidation - Error : ${JSON.stringify(e)}`);
    }
  }


  private initVariables() {
    const fName = 'initVariables';
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - initVariables - START');
      this._loggerService.log(api.LOG_LEVEL.Trace, fName, 'Config from Studio', { config: this._config });

      this._webAppPathURL = this._config.getValue('variables', 'ServerURL');
      this._restApiPort = this._config.getValue('variables', 'RestApiPort') || '8445';
      this._longPollingPort = this._config.getValue('variables', 'LongPollingPort') || '7443';
      this._webAppPath = `${this._webAppPathURL}:${this._restApiPort}/finesse`;
      this._davinciToChannel = this._config.getValue('variables', 'DavinciToChannelPresence');
      this._channelToDavinci = this._config.getValue('variables', 'ChannelToDavinciPresence');
      this._davinciToChannelLogout = this._config.getValue('variables', 'DavinciToChannelLogout');
      this._channelToDavinciLogout = this._config.getValue('variables', 'ChannelToDavinciLogout');
      this._generatedUniqueIdentifierName = this._config.getValue('variables', 'GeneratedUniqueIdentifierName') || 'user.media.id';
      this._uniqueIdentifierCADNames = this._config.getValue('variables', 'UniqueIdentifierCADNames') || {};
      this._useCustomUniqueIdentifier = this._config.getValue('variables', 'UseCustomUniqueIdentifier') || false;
      this._onInteractionPresnece = this._config.getValue('variables', 'OnInteractionPresence');
      this._scenarioIdCadName = this._config.getValue('variables', 'scenarioIdCadName');
      this._customerNumberCADECCName = this._config.getValue('variables', 'customerNumberCADECCName');
      this._isCallbackECCName = this._config.getValue('variables', 'IsCallbackECCName');
      this._connectionIntervalTimer = this._config.getValue('variables', 'connectionCheckInterval') || 60000;

      this._notReadyIdToName = {};
      this._notReadyNameToId = {};
      this._logoutIdToName = {};
      this._logoutNameToId = {};
      this._interactionDetails = {};
      this._wrapupNameToId = {};
      this._wrapupIdToName = {};
      this._isOnInteraction = false;
      this._scenarioList = {};
      this._interactionIdToScenarioID = {};
      this._selfTrigerredJabberDisconnect = false;
      this._droppedInteractions = new Set();
      this._currentPresence = '';

      this._userAttributesCameFromCloud = {};
      this._username = '';

      this.localStorage = window.localStorage;
      this.sessionStorage = window.sessionStorage;
      this.doWrapupCodesExist = false;

      this._finesse_version = '12';

      // Generate and store a unique resourceName (web socket connection name) for each tab
      if (!this.sessionStorage.getItem(GlobalConstants.CONNECTION_NAME)) {
        this.sessionStorage.setItem(GlobalConstants.CONNECTION_NAME, 'AMCCiscoFinesse' + uuidv4());
      }

      if (this.localStorage.getItem(GlobalConstants.INTERACTION_TYPE_LIST)) {
        const retrievedObject = this.localStorage.getItem(GlobalConstants.INTERACTION_TYPE_LIST);
        this._interactionTypeList = JSON.parse(retrievedObject);
      } else {
        this._interactionTypeList = {};
      }

      this._ciscoClient = new jabberwerx.Client(this.sessionStorage.getItem(GlobalConstants.CONNECTION_NAME));

      this._loggerService.logger.logInformation('FINESSE - FinesseService - initVariables - END');
    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Failed to initialize variables', error);
    }
  }

  private async registerAmcEvents() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - registerAmcEvents - START');

      await api.registerContextualControls(this.makeCall);
      await api.registerOnPresenceChanged(this.setPresenceInFinesse);
      api.registerOnLogout((async (reason?: string) => {
        this._loggerService.logger.logDebug('FINESSE - FinesseService - logout - Logout triggered from DaVinci.');

        this.disconnectJabberwerx(false);
      }).bind(this));

      if (this._config.getValue('variables', 'EnableClickToDial')) {
        api.enableClickToDial(true);
        api.registerClickToDial(this.clickToDial);
      }

      api.addContextualContacts([]);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - registerAmcEvents - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - registerAmcEvents - Error : ${error.message || error}`);
    }
  }

  private registerPreLoginJwEvents() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - registerPreLoginJwEvents - START');

      this._ciscoClient.event('clientStatusChanged').bind(this.userLoginStatusChanged);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - registerPreLoginJwEvents - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - registerPreLoginJwEvents - Error: ${error.message || error}`);
    }
  }

  private registerPostLoginJwEvents() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - registerPostLoginJwEvents - START');

      this._ciscoClient.event('messageReceived').bindWhen('event[xmlns=\'http://jabber.org/protocol/pubsub#event\'] items item notification', this.eventHandler);
      this._ciscoClient.event('presenceReceived').bind(this.onClientIgPresenceReceived);
      this._ciscoClient.event('iqReceived').bind(this.onClientIgPresenceReceived);
      this._ciscoClient.event('clientDisconnected').bind(this.onClientDisconnected);
      this._ciscoClient.event('clientConnected').bind(this.onClientConnected);
      this._ciscoClient.event('reconnectCountdownStarted').bind(this.onClientReconnectCountDownStarted);
      this._ciscoClient.event('reconnectCanceled').bind(this.onClientReconnectCanceled);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - registerPostLoginJwEvents - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - registerPostLoginJwEvents - Error: ${error.message || error}`);
    }
  }

  private createConnectionTestInterval() {
    const fName = 'createConnectionTestInterval';
    try {
      this._loggerService.log(api.LOG_LEVEL.Trace, fName, 'Creating connection test interval');
      this._connectionCheckInterval = setInterval(() => {
        try {
          const finesseConnection = this._ciscoClient.isConnected();
          this._loggerService.log(api.LOG_LEVEL.Loop, fName, 'Checking if still connected to Finesse', { finesseConnection });
        } catch (error) {
          this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Error checking if still connected to Finesse', error);
        }
      }, this._connectionIntervalTimer);
    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Error creating connection test interval', error);
    }
  }

  private cleanup() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - cleanup - START');

      this.clearLocalStorage();
      this.clearSessionStorage();

      this._loggerService.logger.logInformation('FINESSE - FinesseService - cleanup - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - cleanup - Error : ${error.message || error}`);
    }
  }

  private clearLocalStorage() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - clearLocalStorage - START');

      this.localStorage.removeItem(GlobalConstants.DOMAIN_KEY);
      this.localStorage.removeItem(GlobalConstants.EXTENSION_KEY);
      this.localStorage.removeItem(GlobalConstants.PWD_KEY);
      this.localStorage.removeItem(GlobalConstants.USERNAME_KEY);
      this.localStorage.removeItem(GlobalConstants.INTERACTION_TYPE_LIST);
      this.localStorage.removeItem(GlobalConstants.OUTBOUND_CALLID_SCENARIOID_MAPPING);
      this.localStorage.removeItem(GlobalConstants.DEVICE_KEY);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - clearLocalStorage - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - clearLocalStorage - Error : ${error.message || error}`);
    }
  }

  private clearSessionStorage() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - clearSessionStorage - START');

      this.sessionStorage.removeItem(GlobalConstants.CONNECTION_NAME);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - clearSessionStorage - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - clearSessionStorage - Error: ${error.message || error}`);
    }
  }

  private setLocalStorage() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - setLocalStorage - START');

      this.localStorage.setItem(GlobalConstants.USERNAME_KEY, btoa(this._user.username));
      this.localStorage.setItem(GlobalConstants.EXTENSION_KEY, btoa(this._user.extension));
      this.localStorage.setItem(GlobalConstants.DOMAIN_KEY, btoa(this._user.domain));
      this.localStorage.setItem(GlobalConstants.PWD_KEY, btoa(this._user.password));

      this._loggerService.logger.logInformation('FINESSE - FinesseService - setLocalStorage - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - setLocalStorage - Error: ${error.message || error}`);
    }
  }

  private getNumberOfDevices(devices): number {
    const fname = 'getNumberOfDevices()';
    this._loggerService.logger.logTrace(`FINESSE - ${this.cname} - ${fname}: BEGIN`);

    try {
      if (devices && devices !== -1) {
        this._loggerService.logger.logDebug(`FINESSE - ${this.cname} - ${fname}: Num_Devices=${devices.Devices.Device.length || 1}`);
        return (devices.Devices.Device.length) as number || 1;
      }

      this._loggerService.logger.logInformation(`FINESSE - ${this.cname} - ${fname}: Num_Devices=0`);
      return 0;
    } catch (e) {
      this._loggerService.logger.logError(`FINESSE - ${this.cname} - ${fname}: Error=${JSON.stringify(e)}`);

      // Throw error, we want the return value to mean something. If we return "undefined", other issues might occur
      // if we use the device count as a boolean
      throw e;
    } finally {
      this._loggerService.logger.logTrace(`FINESSE - ${this.cname} - ${fname}: END`);
    }

  }

  private async getDevices(): Promise<any> {
    const fname = 'getDevices()';
    this._loggerService.logger.logTrace(`FINESSE - ${this.cname} - ${fname}: BEGIN`);

    try {
      const method = 'GET';
      const url = `${this._webAppPath}/api/Devices?extension=${this._user.extension}`;
      const devices = this._xmlParser.parse(
        await this.sendRestRequest(method, url).toPromise()
      );

      return devices;
    } catch (e) {
      if (e.status === 404) {
        const error = this._xmlParser.parse(e.error);
        if (error.ApiError && error.ApiError.ErrorType === 'DEVICES_NOT_FOUND') {
          this._loggerService.logger.logError(`FINESSE - ${this.cname} - ${fname}: No devices found for Ext: ${this._user.extension}`);
        } else {
          this._loggerService.logger.logDebug(`FINESSE - ${this.cname} - ${fname}: It seems the customer is on version 11.6 of Finesse`);
          this._finesse_version = '11';
          return -2; // TODO: we can do better probably
        }
      } else {
        this._loggerService.logger.logError(`FINESSE - ${this.cname} - ${fname}: ${JSON.stringify(e)} | EXT: ${this._user.extension}`);

        api.sendNotification(`Error getting devices for EXT ${this._user.extension}. Please check your login details and if the issue persists, contact your administrator.`, NOTIFICATION_TYPE.Error);

        return -1;
      }
    } finally {
      this._loggerService.logger.logTrace(`FINESSE - ${this.cname} - ${fname}: END`);
    }
  }

  private async timeout(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private sendRestRequest(method: string, url: string, xmlData?: string, reqHeaders?: any): Observable<any> {
    const fName = 'sendRestRequest';
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - sendRestRequest - START');
      this._loggerService.log(api.LOG_LEVEL.Debug, fName, 'Sending REST request', { method, url, xmlData, reqHeaders });
      if (method === 'PUT' || method === 'POST') {
        this._loggerService.logger.logInformation(`FINESSE - FinesseService - sendRestRequest - Request body: ${xmlData}`);
      }

      reqHeaders = reqHeaders || {};
      reqHeaders['Authorization'] = `Basic ${this._user.credentials}`;
      const queryParameters = new HttpParams().set('bypassServerCache', 'true&nocache');

      switch (method) {
      case 'PUT': {
        reqHeaders['Accept'] = 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8';
        reqHeaders['Content-Type'] = 'application/xml';

        return this._http.put(url, xmlData, {
          headers: reqHeaders,
          observe: 'response' as 'response',
          params: queryParameters,
        });
      }
      case 'GET': {
        reqHeaders['Accept'] = 'application/xml';

        return this._http.get(url, {
          headers: reqHeaders,
          observe: 'body',
          responseType: 'text',
        });
      }
      case 'POST': {
        reqHeaders['Accept'] = 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8';
        reqHeaders['Content-Type'] = 'application/xml';

        return this._http.post(url, xmlData, {
          headers: reqHeaders,
          observe: 'body',
          responseType: 'text',
        });
      }
      }

      this._loggerService.logger.logInformation('FINESSE - FinesseService - sendRestRequest - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - sendRestRequest - Error: ${error.message || error}`);

    }
  }

  private async handleDialog(selectedData) {
    const cname = 'FinesseService';
    const fname = 'handleDialog';

    this._loggerService.logger.logDebug(`${cname} - ${fname} - START`);

    try {
      this._loggerService.logger.logInformation(`FINESSE - FinesseService - handleDialog - selectedData: ${JSON.stringify(selectedData)}`);

      // Check if any api errors were raised with this event.
      if (selectedData.apiErrors) {
        const errors: any = selectedData.apiErrors.length ?
          selectedData.apiErrors : [selectedData.apiErrors.apiError];

        this._loggerService.logger.logError(`FINESSE - ${this.cname} - ${fname}: Errors=${JSON.stringify(errors)}`);

        for (const error in errors) {
          if (errors[error].peripheralErrorMsg === 'CCX_AGENT_DEVICE_OFF') {
            api.sendNotification(`${this.getNumberOfDevices(await this.getDevices())} devices found for EXT ${this._user.extension}, please add or ` +
              'turn on a Cisco device for this extension, or logout to sign into ' +
              `a different extension. Finesse Error: "${errors[error].peripheralErrorText || JSON.stringify(errors[error])}"`,
            NOTIFICATION_TYPE.Error);

            await this.disconnectJabberwerx(true);

            return;
          }
        }
      }

      if (selectedData.dialogs && selectedData.dialogs.Dialog) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - selectedData has dialogs and Dialog.`);

        const dataToSend = selectedData.dialogs.Dialog;

        this._loggerService.logger.logInformation(`${cname} - ${fname} - Sending this data: ${JSON.stringify(dataToSend)}`);

        const result = await this.createIInteraction(dataToSend);

        if (result) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - createIInteraction succeeded. result=${JSON.stringify(result)}`);

          const scenario = this._operations.constructUIInteraction(dataToSend);
          this.callSubject.next(scenario);
        }
      } else if (selectedData.dialog) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - selectedData has dialog.`);

        const dataToSend = selectedData.dialog;

        this._loggerService.logger.logInformation(`${cname} - ${fname} - Sending this data: ${JSON.stringify(dataToSend)}`);

        const result = await this.createIInteraction(dataToSend);

        if (result) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - createIInteraction succeeded. result=${JSON.stringify(result)}`);

          const scenario = this._operations.constructUIInteraction(dataToSend);
          this.callSubject.next(scenario);
        }
      } else if (selectedData.Dialogs && selectedData.Dialogs.Dialog) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - selectedData has Dialogs and Dialog.`);

        const dialogs = selectedData.Dialogs.Dialog;

        if (Array.isArray(dialogs)) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - Dialog is an Array`);

          for (let i = 0; i < dialogs.length; i++) {
            this._loggerService.logger.logLoop(`${cname} - ${fname} - dialog[${i}]=${JSON.stringify(dialogs[i])}`);

            const dataToSend = dialogs[i];

            const result = await this.createIInteraction(dataToSend);

            if (result) {
              this._loggerService.logger.logTrace(`${cname} - ${fname} - createIInteraction succeeded. result=${JSON.stringify(result)}`);

              const scenario = this._operations.constructUIInteraction(dataToSend);
              this.callSubject.next(scenario);
            }
          }
        } else {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - Dialog is not an Array.`);

          const dataToSend = dialogs;
          const result = await this.createIInteraction(dataToSend);

          if (result) {
            this._loggerService.logger.logTrace(`${cname} - ${fname} - createIInteraction succeeded. result=${JSON.stringify(result)}`);

            const scenario = this._operations.constructUIInteraction(dataToSend);
            this.callSubject.next(scenario);
          }
        }
      } else if (selectedData.user) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - Selected Data has user.`);
        this.setPresenceInFramework(selectedData.user.state, selectedData.user.reasonCodeId);
      }
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - handleDialog - Error: ${error.message || JSON.stringify(error)}`
      );
    } finally {
      this._loggerService.logger.logDebug('FINESSE - FinesseService - handleDialog - END');
    }
  }

  private async setupFinesse() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - setupFinesse - END');

      await this.initReasonCodes();
      await this.getWrapupReasons();
      const dialogs = await this.getDialogs();
      this.handleDialog(dialogs);

      this._loggerService.logger.logInformation('FINESSE - FinesseService - setupFinesse - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - setupFinesse - Error: ${error.message || error}`);
      throw error;
    }
  }

  private async getDialogs() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - getDialogs - START');


      const method = 'GET';
      const url = `${this._webAppPath}/api/User/${this._user.username}/Dialogs`;

      const dialogs = this._xmlParser.parse(
        await this.sendRestRequest(method, url).toPromise()
      );

      this._loggerService.logger.logInformation('FINESSE - FinesseService - getDialogs - END');
      return dialogs;
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - getDialogs - Error: ${error.message || error}`);

      api.sendNotification('There was an error getting dialogs from Finesse. \
      Please log in and try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);

      await this.disconnectJabberwerx(true);
      this.clearLocalStorage();
      error.rethrown = true;

      throw error;
    }
  }

  private parseReasonCodes(reasonCodes: any, reasonType: string, systemType = false) {
    const fName = 'parseReasonCodes';
    try {
      this._loggerService.log(api.LOG_LEVEL.Debug, fName, 'Parsing reason codes', { reasonCodes, reasonType, systemType });
      if (reasonCodes) {
        const targetIdToNameMap = (reasonType === 'NOT_READY') ? this._notReadyIdToName : this._logoutIdToName;
        const targetNameToIdMap = (reasonType === 'NOT_READY') ? this._notReadyNameToId : this._logoutNameToId;
        this._loggerService.log(api.LOG_LEVEL.Debug, fName, 'Target maps before parsing reason codes.', { reasonType, targetIdToNameMap, targetNameToIdMap });
        if (Array.isArray(reasonCodes)) {
          // 2 or more codes
          for (const reasonCode of reasonCodes) {
            if (reasonCode.id) {
              targetIdToNameMap[reasonCode.id] = reasonCode.label;
              targetNameToIdMap[reasonCode.label] = reasonCode.id;
            } else if (reasonCode.uri) {
              const uri = reasonCode.uri;
              const parts = uri.split('/');
              const id = parts[parts.length - 1];
              targetIdToNameMap[id] = reasonCode.label;
              targetNameToIdMap[reasonCode.label] = id;
            } else {
              // No codes, or something went wrong parsing response XML, or unknown response
              this._loggerService.log(api.LOG_LEVEL.Debug, fName, 'No codes for type, something may have gone wrong.', { reasonType, systemType, reasonCodes });
            }
          }
        } else {
          if (reasonCodes.id) {
            // Exactly 1 code
            targetIdToNameMap[reasonCodes.id] = reasonCodes.label;
            targetNameToIdMap[reasonCodes.label] = reasonCodes.id;
          } else if (reasonCodes.uri) {
            const uri = reasonCodes.uri;
            const parts = uri.split('/');
            const id = parts[parts.length - 1];
            targetIdToNameMap[id] = reasonCodes.label;
            targetNameToIdMap[reasonCodes.label] = id;
          } else {
            // No codes, or something went wrong parsing response XML, or unknown response
            this._loggerService.log(api.LOG_LEVEL.Debug, fName, 'No codes for type, something may have gone wrong.', { reasonType, systemType, reasonCodes });
          }
        }
        this._loggerService.log(api.LOG_LEVEL.Debug, fName, 'Target maps after parsing reason codes.', { reasonType, targetIdToNameMap, targetNameToIdMap });
      }
    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, fName, 'Failed to parse reason codes.', error);
    }
  }

  private async initReasonCodes() {
    try {
      this._loggerService.logger.logInformation(
        'FINESSE - FinesseService - initReasonCodes - START'
      );

      let reasonType = 'NOT_READY';
      const notReadyCodesUsers = this._xmlParser.parse(
        await this.getReasonCodes(reasonType)
      ).ReasonCodes.ReasonCode;
      this.parseReasonCodes(notReadyCodesUsers, reasonType, false);

      const notReadyCodesSystemXML = await this.getReasonCodes(reasonType, true);
      if (notReadyCodesSystemXML) {
        const notReadyCodesSystem = this._xmlParser.parse(notReadyCodesSystemXML
        ).ReasonCodes.ReasonCode;
        this.parseReasonCodes(notReadyCodesSystem, reasonType, true);

      }


      reasonType = 'LOGOUT';
      const logoutReasonCodesUsers = this._xmlParser.parse(
        await this.getReasonCodes(reasonType)
      ).ReasonCodes.ReasonCode;
      this.parseReasonCodes(logoutReasonCodesUsers, reasonType, false);

      const logoutReasonCodesSystemXML = await this.getReasonCodes(reasonType, true);
      if (logoutReasonCodesSystemXML) {
        const logoutReasonCodesSystem = this._xmlParser.parse(
          logoutReasonCodesSystemXML
        ).ReasonCodes.ReasonCode;
        this.parseReasonCodes(logoutReasonCodesSystem, reasonType, true);
      }

      this._loggerService.log(
        api.LOG_LEVEL.Debug, 'initReasonCodes', 'Reason codes after parsing', { notReadyIdToName: this._notReadyIdToName, notReadyNameToId: this._notReadyNameToId, logoutIdToName: this._logoutIdToName, logoutNameToId: this._logoutNameToId }
      );
      this._loggerService.logger.logInformation(
        'FINESSE - FinesseService - initReasonCodes - END'
      );
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - initReasonCodes - Error: ${error.message || error}`);

      api.sendNotification('There was an error getting reason codes from Finesse. \
      Please log in and try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
    }
  }

  private async getReasonCodes(category: string, isSystemCode = false): Promise<any> {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - getReasonCodes - END');
      this._loggerService.log(api.LOG_LEVEL.Debug, 'getReasonCodes', 'Getting reason codes', { category, isSystemCode });
      const method = 'GET';
      let url: string;
      if (isSystemCode) {
        url = `${this._webAppPath}/api/ReasonCodes?category=${category}`;
      } else {
        url = `${this._webAppPath}/api/User/${this._user.username}/ReasonCodes?category=${category}`;
      }

      const result = await this.sendRestRequest(method, url).toPromise();
      this._loggerService.log(api.LOG_LEVEL.Debug, 'getReasonCodes', 'Result from Finesse', { result });
      return result;
    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, 'getReasonCodes', 'Failed to get reason codes from Finesse.', error);
    }
  }

  private async getWrapupReasons() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - getWrapupReasons - START');

      const method = 'GET';
      const url = `${this._webAppPath}/api/User/${this._user.username}/WrapUpReasons`;

      const wrapupCodes = this._xmlParser.parse(
        await this.sendRestRequest(method, url).toPromise()
      ).WrapUpReasons.WrapUpReason;

      if (wrapupCodes && Array.isArray(wrapupCodes)) {
        // 2 or more codes
        for (const wrapupCode of wrapupCodes) {
          const id = wrapupCode.uri.split('/').pop();
          const name = wrapupCode.label;

          this._wrapupIdToName[id] = name;
          this._wrapupNameToId[name] = id;
        }

        this.doWrapupCodesExist = true;
      } else if (wrapupCodes && wrapupCodes.uri && wrapupCodes.label) {
        // Exactly 1 code
        // Untested - No env with just 1 disposition code
        const id = wrapupCodes.uri.split('/').pop();
        const name = wrapupCodes.label;

        this._wrapupIdToName[id] = name;
        this._wrapupNameToId[name] = id;

        this.doWrapupCodesExist = true;
      } else {
        // No codes, or something went wrong parsing response XML, or unknown response
        this.doWrapupCodesExist = false;
        this._loggerService.logger.logInformation(`FINESSE - FinesseService - getWrapupReasons : No wrapup codes, or something went wrong parsing them: ${wrapupCodes}`);
      }

      this._loggerService.logger.logInformation('FINESSE - FinesseService - getWrapupReasons - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - getWrapupReasons - Error: ${error.message || error}`);

      api.sendNotification('There was an error getting wrapup codes from Finesse. \
      Please log in and try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);

      await this.disconnectJabberwerx(true);
      this.clearLocalStorage();
      error.rethrown = true;

      throw error;
    }
  }

  private async getState() {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - getState - START');
      const method = 'GET';
      const url = `${this._webAppPath}/api/User/${this._user.username}`;

      const userInfo = this._xmlParser.parse(
        await this.sendRestRequest(method, url).toPromise()
      );
      this._loggerService.log(api.LOG_LEVEL.Debug, 'getState', 'Result from Finesse', { userInfo });
      this._loggerService.logger.logInformation('FINESSE - FinesseService - getState - END');

      return userInfo;
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - getState - Error: ${error.message || error}`);
    }
  }

  private async setState(state: string, reasonCodeId?: string) {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - setState - START');
      this._loggerService.log(api.LOG_LEVEL.Debug, 'setState', 'Setting state in Finesse', { state, reasonCodeId });
      const method = 'PUT';
      const url = `${this._webAppPath}/api/User/${this._user.username}`;
      let xmlData = '';
      if (state === 'READY') {
        xmlData = `<User><state>${state}</state></User>`;
      } else {
        xmlData = `<User><state>${state}</state><reasonCodeId>${reasonCodeId}</reasonCodeId></User>`;
      }

      const result = await this.sendRestRequest(method, url, xmlData).toPromise();
      this._loggerService.log(api.LOG_LEVEL.Debug, 'setState', 'Result from Finesse', { result });
      this._loggerService.logger.logInformation('FINESSE - FinesseService - setState - END');
      return result;
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - setState - Error: ${error.message || error}`);

      api.sendNotification('There was an error setting the state. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
    }
  }

  private async clearLostInteraction(callId: string) {
    const fname = 'clearLostInteraction';
    const cname = 'finesse.service';

    this._loggerService.logger.logDebug(`${cname} - ${fname} - Begin`);

    try {
      const callIdToScenarioId = JSON.parse(this.localStorage.getItem(GlobalConstants.OUTBOUND_CALLID_SCENARIOID_MAPPING)) ?? {};
      // Track the scenario id for a lost interaction in case the local storage is cleared
      let scenarioId = '';

      if (!callIdToScenarioId) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - Debug : callIdToScenarioId is null`);
        return;
      }

      if (!callIdToScenarioId[callId]) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - Debug : no record for callId ${callId} in callIdToScenarioId`);
        for (const scenario in this._scenarioMap) {
          if (
            this._scenarioMap[scenario][callId] &&
              (this._scenarioMap[scenario][callId].state === INTERACTION_STATES.Connected ||
              this._scenarioMap[scenario][callId].state === INTERACTION_STATES.OnHold)
          ) {
            scenarioId = scenario;
          }
        }

        if (scenarioId === '') {
          return;
        }

        this._loggerService.logger.logTrace(`${cname} - ${fname} - Debug : active record with ${callId} in scenarioMap`);
      }

      if (scenarioId === '' && callIdToScenarioId[callId][callId] && !this._scenarioMap[callIdToScenarioId[callId]][callId]) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - Debug : No interaction in _scenarioMap for scenarioId: ${callIdToScenarioId[callId]}, interactionId (callId): ${callId}`);
        return;
      }

      const interaction = callIdToScenarioId[callId] ? this._scenarioMap[callIdToScenarioId[callId]][callId] : this._scenarioMap[scenarioId][callId];

      // Add to Dropped Interactions
      this._droppedInteractions.add(interaction.interactionId);

      // Delete interaction in Interaction Type List and save list to local storage
      delete this._interactionTypeList[interaction.interactionId];
      this.localStorage.setItem(GlobalConstants.INTERACTION_TYPE_LIST, JSON.stringify(this._interactionTypeList));

      delete this._scenarioList[interaction.scenarioId][interaction.interactionId];

      // If scenarioList[scenarioId] is empty...
      if (Object.keys(this._scenarioList[interaction.scenarioId]).length === 0) {
        // delete the key
        this._loggerService.logger.logTrace(`${cname} - ${fname} - Debug : scenarioList[${interaction.scenarioId}] is empty.`);

        delete this._scenarioList[interaction.scenarioId];

        // If that list contains the callId...
        if (callIdToScenarioId[callId]) {
          this._loggerService.logger.logInformation(`FINESSE - FinesseService - createInteraction - No more interactions for scenario ID: ${interaction.scenarioId}. Removing callId ${callId} from local storage.`);

          // delete the key
          delete callIdToScenarioId[callId];

          // store list to local storage
          this.localStorage.setItem(GlobalConstants.OUTBOUND_CALLID_SCENARIOID_MAPPING, JSON.stringify(callIdToScenarioId));
        }

        // If scenarioList is empty, set _isOnInteraction to false.
        if (Object.keys(this._scenarioList).length === 0) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - Debug : No more scenarios in scenarioList.`);
          this._isOnInteraction = false;
        }
      }

      const userInfo: any = await this.getState();
      this._loggerService.logger.logInformation(`FINESSE - FinesseService - clearLostInteraction - The state info from finesse is ${userInfo.User.state} and the reason code is ${userInfo.User.reasonCodeId}`);
      await this.setPresenceInFramework(userInfo.User.state, userInfo.User.reasonCodeId);

      interaction.state = INTERACTION_STATES.Disconnected;

      api.setInteraction(interaction);

      this._scenarioMap[interaction.scenarioId][interaction.interactionId] = interaction;

      this.removeAndSetInteractions(callId);
    } catch (e) {
      this._loggerService.logger.logError(`${cname} - ${fname} - Error : ${e.message || e}`);
    } finally {
      this._loggerService.logger.logDebug(`${cname} - ${fname} - End`);
    }
  }

  private removeAndSetInteractions(callId: string) {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - removeAndSetInteractions - START');

      const interactions = this.callSubject.getValue()
        .interactions.filter(
          (interaction) => interaction.interactionId.toString() !== callId
        );

      this.callSubject.next({ interactions: interactions });
      this._loggerService.logger.logInformation('FINESSE - FinesseService - removeAndSetInteractions - END');
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - removeAndSetInteractions - Error: ${error.message || error}`);
    }
  }

  private getDavinciInteractionState(callState: string) {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - getDavinciInteractionState - START');

      switch (callState) {
      case 'ACTIVE':
        return INTERACTION_STATES.Connected;
      case 'INITIATING':
      case 'INITIATED':
        return INTERACTION_STATES.Initiated;
      case 'DROPPED':
      case 'WRAP_UP':
        return INTERACTION_STATES.Disconnected;
      case 'ALERTING':
        return INTERACTION_STATES.Alerting;
      case 'HELD':
        return INTERACTION_STATES.OnHold;
      }
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - getDavinciInteractionState - Error: ${error.message || error}`);
    }
  }

  // Checks an object to see if it has an HTTP status field. (Checks if numerical 'status' field is present).
  // If we send a REST request to finesse API, and the request fails, then
  // an error will be thrown. This function is used to check error objects
  // in functions which make requests to finesse so that we may access the status code and take remedial
  // action accordingly, depending on the returned status code.
  private hasHttpStatusCode(error: any): boolean {
    return error &&
      error['status'] &&
      !isNaN(parseInt(error['status'], 10));
  }

  /**
   * Logs out and disconnects Jabberwerx
   * @param selfTriggered: boolean - If true, causes Finesse logout, but not log out of Framework. If false, logs out of both.
   */
  private async disconnectJabberwerx(selfTriggered: boolean) {
    try {
      this._loggerService.logger.logInformation('FINESSE - FinesseService - disconnectJabberwerx - START');
      this._loggerService.log(api.LOG_LEVEL.Debug, 'disconnectJabberwerx', 'Disconnecting Jabberwerx', { selfTriggered });

      this._selfTrigerredJabberDisconnect = selfTriggered;

      await this.logout();

      if (this._ciscoClient) {
        this._ciscoClient.disconnect();
      }

      this._loggerService.logger.logInformation('FINESSE - FinesseService - disconnectJabberwerx - END');
    } catch (error) {
      this._loggerService.log(api.LOG_LEVEL.Error, 'disconnectJabberwerx', 'Failed to disconnect Jabberwerx.', error);
    }
  }

  // TODO: There is a small CAD update issue. Ex: Two intractions come back to back (INITIATING > INITIATED)
  // TODO: We set scenarioID for 1st one, which generates a new event. In the meanwhile 2nd interaction comes
  // TODO: This also does not have scenarioID, so we update CAD again, which generates another event
  // TODO: Does not effect functionality, has a small performace impact
  private async createIInteraction(callData: any): Promise<boolean> {
    const cname = 'FinesseService';
    const fname = 'createIInteraction';

    this._loggerService.logger.logDebug('FINESSE - FinesseService - createIInteraction - START');

    try {
      this._loggerService.logger.logInformation(`${cname} - ${fname} - callData: ${JSON.stringify(callData)}`);

      if (callData?.mediaProperties?.callType === 'CONFERENCE') {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - Call Type is CONFERENCE, checking if only 2 participants, if so, removing isCOnference flag`);

        if (Array.isArray(callData.participants.Participant) && callData.participants.Participant.length < 3) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - Call Type is CONFERENCE and only 2 participants, removing isConference flag`);

          if (this._interactionDetails[callData.id.toString(10)]) {
            this._interactionDetails[callData.id.toString(10)] = {
              isConference: false,
            };
          }
        }
      }

      if (this._droppedInteractions.has(callData.id.toString(10))) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - droppedInteractions contains callId. callId=${JSON.stringify(callData.id.toString(10))}`);

        const dialogs = await this.getDialogs();
        let dialog: any;
        if (dialogs.dialogs && dialogs.dialogs.Dialog) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - has dialogs and Dialog`);

          dialog = dialogs.dialogs.Dialog;

          if (dialog.id.toString(10) !== callData.id.toString(10)) {
            this._loggerService.logger.logTrace(`${cname} - ${fname} - dialog does not match callId=${JSON.stringify(callData.id.toString(10))}, returning FALSE`);

            return false;
          }
        } else if (dialogs.dialog) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - has dialog.`);

          dialog = dialogs.dialog;

          if (dialog.id.toString(10) !== callData.id.toString(10)) {
            this._loggerService.logger.logTrace(`${cname} - ${fname} - dialog does not match callId=${JSON.stringify(callData.id.toString(10))}, returning FALSE`);

            return false;
          }
        } else if (dialogs.Dialogs && dialogs.Dialogs.Dialog) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - has Dialogs and Dialog.`);

          const DIALOGS = dialogs.Dialogs.Dialog;

          if (Array.isArray(DIALOGS)) {
            this._loggerService.logger.logTrace(`${cname} - ${fname} - DIALOGS is an Array.`);

            let result = false;

            for (let i = 0; i < DIALOGS.length; i++) {
              this._loggerService.logger.logLoop(`${cname} - ${fname} - dialog[${i}]=${JSON.stringify(dialogs[i])}`);

              dialog = dialogs[i];

              if (dialog.id.toString(10) === callData.id.toString(10)) {
                this._loggerService.logger.logTrace(`${cname} - ${fname} - dialog matches callId=${JSON.stringify(callData.id.toString(10))}. BREAK FROM LOOP`);
                result = true;

                break;
              }
            }

            if (!result) {
              this._loggerService.logger.logTrace(`${cname} - ${fname} - result=${JSON.stringify(result)}. return FALSE.`);

              return false;
            }
          } else {
            this._loggerService.logger.logTrace(`${cname} - ${fname} - DIALOGS is not an Array.`);

            dialog = DIALOGS;

            if (dialog.id.toString(10) !== callData.id.toString(10)) {
              this._loggerService.logger.logTrace(`${cname} - ${fname} - dialog does not match callId=${JSON.stringify(callData.id.toString(10))}, return FALSE`);

              return false;
            }
          }
        }

        this._droppedInteractions.delete(callData.id.toString(10));
      }

      let agent: any;

      let canUpdateTheCall = false;

      if (Array.isArray(callData.participants.Participant)) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - callData.participants.Participant is an Array`);

        for (const participant of callData.participants.Participant) {
          this._loggerService.logger.logLoop(`${cname} - ${fname} - participant=${JSON.stringify(participant)}`);

          if (participant.mediaAddress.toString(10) === this._user.extension) {
            this._loggerService.logger.logTrace(`${cname} - ${fname} - mediaAddress matches _user.extension=${JSON.stringify(this._user.extension)}, BREAK FROM LOOP`);

            agent = participant;

            if (agent.actions && agent.actions.action) {
              this._loggerService.logger.logTrace(`${cname} - ${fname} - agent has actions and action field.`);

              for (let i = 0; i < agent.actions.action.length; i++) {
                this._loggerService.logger.logLoop(`${cname} - ${fname} - action[${i}]=${JSON.stringify(agent.actions.action[i])}`);

                if (agent.actions.action[i] === 'UPDATE_CALL_DATA') {
                  this._loggerService.logger.logTrace(`${cname} - ${fname} - action=UPDATE_CALL_DATA, BREAK FROM LOOP`);

                  canUpdateTheCall = true;

                  break;
                }
              }
            }

            break;
          }
        }
      } else {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - callData.participants.Participant is not an Array`);
        if (callData.participants.Participant['mediaAddress'].toString(10) === this._user.extension) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - mediaAddress matches _user.extension=${JSON.stringify(this._user.extension)}`);

          agent = callData.participants.Participant;

          if (agent.actions && agent.actions.action) {
            this._loggerService.logger.logTrace(`${cname} - ${fname} - agent has actions and action field.`);

            for (let i = 0; i < agent.actions.action.length; i++) {
              this._loggerService.logger.logLoop(`${cname} - ${fname} - action[${i}]=${JSON.stringify(agent.actions.action[i])}`);

              if (agent.actions.action[i] === 'UPDATE_CALL_DATA') {
                this._loggerService.logger.logTrace(`${cname} - ${fname} - action=UPDATE_CALL_DATA, BREAK FROM LOOP`);

                canUpdateTheCall = true;

                break;
              }
            }
          }
        }
      }

      const callState = agent.state;

      if (agent.state === 'INITIATING' || agent.state === 'FAILED' || callData.state === 'FAILED') {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - agent.state=${JSON.stringify(agent.state)}, callData.state=${JSON.stringify(callData.state)}, return FALSE`);
        if (callData.state === 'FAILED') {
          api.sendNotification('The call failed, please check the dialed number and try again. If the issue persists, please contact your administrator.', NOTIFICATION_TYPE.Error);
        }

        return false;
      }

      let customerNumber = '';

      let isItCallback = false;

      for (const callVariable of callData.mediaProperties.callvariables.CallVariable) {
        this._loggerService.logger.logLoop(`${cname} - ${fname} - callVariable=${JSON.stringify(callVariable)}`);
        if (callVariable.name === this._isCallbackECCName && callVariable.value === true) {
          isItCallback = true;
        }
        if (callVariable.name === this.customerNumberCADECCName) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - callVariable.name matches _customerNumberCADECCName=${JSON.stringify(this._customerNumberCADECCName)}`);
          customerNumber = callVariable.value.toString();
        }
      }


      let isInbound = true;

      if (agent.state === 'INITIATED') {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - agent.state=${JSON.stringify(agent.state)}`);

        isInbound = false;
        this._interactionTypeList[`${callData.id.toString()}`] = 'OUTBOUND';
        this.localStorage.setItem(GlobalConstants.INTERACTION_TYPE_LIST, JSON.stringify(this._interactionTypeList));
      } else if (this._interactionTypeList[callData.id.toString()]) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - _interactionTypeList has callId=${callData.id.toString()}`);

        isInbound = this._interactionTypeList[callData.id.toString()] !== 'OUTBOUND';
      } else if  (isItCallback && callData.mediaProperties.callType !== 'CONSULT') {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - agent.state=${JSON.stringify(agent.state)}`);

        isInbound = false;
        this._interactionTypeList[`${callData.id.toString()}`] = 'OUTBOUND';
        this.localStorage.setItem(GlobalConstants.INTERACTION_TYPE_LIST, JSON.stringify(this._interactionTypeList));
      } else {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - agent state is not INITIATED and _interactionTypeList does not have CallId=${callData.id.toString()}`);

        this._interactionTypeList[`${callData.id.toString()}`] = 'INBOUND';
        this.localStorage.setItem(GlobalConstants.INTERACTION_TYPE_LIST, JSON.stringify(this._interactionTypeList));
      }

      let direction: any;




      if (callData.mediaProperties.callType === 'CONSULT') {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - callType=${callData.mediaProperties.callType}`);

        direction = INTERACTION_DIRECTION_TYPES.Inbound;
      } else if (isInbound && this.areAllParticipantsAgents(callData.participants.Participant)) {
        direction = INTERACTION_DIRECTION_TYPES.Internal;
      } else {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - callType=${JSON.stringify(callData.mediaProperties.callType)}`);

        direction = isInbound
          ? INTERACTION_DIRECTION_TYPES.Inbound
          : INTERACTION_DIRECTION_TYPES.Outbound;
      }


      let scenarioId: string;
      let scenarioIdFound = false;
      const CAD_OBJECT: {
        [index: string]: IField;
      } = {};

      // TODO: Refactor this block
      if (this._useCustomUniqueIdentifier) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - useCustomUniqueIdentifier=${JSON.stringify(this._useCustomUniqueIdentifier)}`);

        for (const callVariable of callData.mediaProperties.callvariables
          .CallVariable) {
          this._loggerService.logger.logLoop(`${cname} - ${fname} - callVariable=${JSON.stringify(callVariable)}`);

          if (callVariable.name === this._scenarioIdCadName) {
            this._loggerService.logger.logTrace(`${cname} - ${fname} - callVariable name matches _scenarioIdCadName=${this._scenarioIdCadName}, BREAK FROM LOOP`);

            scenarioId = callVariable.value;
            scenarioIdFound = scenarioId ? true : false;

            break;
          }
        }

        if (!scenarioIdFound) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - scenarioIdFound=${JSON.stringify(scenarioIdFound)}`);

          for (const callVariable of callData.mediaProperties.callvariables
            .CallVariable) {
            this._loggerService.logger.logLoop(`${cname} - ${fname} - callVariable=${JSON.stringify(callVariable)}`);

            for (let i = 1; i <= Object.keys(this._uniqueIdentifierCADNames).length; i = i + 1) {
              this._loggerService.logger.logLoop(`${cname} - ${fname} - callVariable=${JSON.stringify(callVariable)}, _uniqueIdentifierCadNames[${i}]=${JSON.stringify(this._uniqueIdentifierCADNames[i])}`);

              if (callVariable.name === this._uniqueIdentifierCADNames[i]) {
                this._loggerService.logger.logTrace(`${cname} - ${fname} - callVariable.name matches uniqueIdentifierCADNames[${i}]=${JSON.stringify(this._uniqueIdentifierCADNames[i])}, BREAK FROM LOOP`);

                scenarioId = callVariable.value;
                scenarioIdFound = scenarioId ? true : false;

                break;
              }
            }

            if (scenarioIdFound) {
              this._loggerService.logger.logTrace(`${cname} - ${fname} - scenarioIdFound=${JSON.stringify(scenarioIdFound)}, BREAK FROM LOOP`);

              break;
            }
          }
        }

        if (!scenarioIdFound) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - scenarioIdFound=${JSON.stringify(scenarioIdFound)}`);

          for (const callVariable of callData.mediaProperties.callvariables.CallVariable) {
            this._loggerService.logger.logLoop(`${cname} - ${fname} - callVariable=${JSON.stringify(callVariable)}`);

            if (callVariable.name === this._generatedUniqueIdentifierName) {
              this._loggerService.logger.logTrace(`${cname} - ${fname} - callVariable.name matches _generatedUniqueIdentifierName=${JSON.stringify(this._generatedUniqueIdentifierName)}, BREAK FROM LOOP`);

              scenarioId = callVariable.value;
              scenarioIdFound = scenarioId ? true : false;

              break;
            }
          }
        }
      } else {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - useCustomUniqueIdentifier=${JSON.stringify(this._useCustomUniqueIdentifier)}`);

        if (callData.callGUID) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - callData has callGuid=${JSON.stringify(callData.callGUID)}`);

          scenarioId = callData.callGUID;
          scenarioIdFound = true;
        } else {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - callData does not have callGUID.`);

          for (const callVariable of callData.mediaProperties.callvariables.CallVariable) {
            this._loggerService.logger.logLoop(`${cname} - ${fname} - callVariable=${JSON.stringify(callVariable)}`);

            if (callVariable.name === 'user.media.id') {
              this._loggerService.logger.logTrace(`${cname} - ${fname} - callVariable.name=${callVariable.name}`);

              scenarioId = callVariable.value;
              scenarioIdFound = scenarioId ? true : false;
            }
          }
        }
      }

      const callId = callData.id.toString();



      if (!customerNumber) {
        if (isInbound && !this.areAllParticipantsAgents(callData.participants.Participant)) {
          customerNumber = callData.fromAddress.toString();
        }
      }

      if (!customerNumber && !isInbound) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - customerNumber=${JSON.stringify(customerNumber)} and isInbound=${JSON.stringify(isInbound)}`);

        customerNumber = callData.toAddress.toString();
      }

      // TODO: This might need some refactoring
      if (!scenarioIdFound || !scenarioId) {
        if (isInbound && this.areAllParticipantsAgents(callData.participants.Participant)) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - Received incoming interaction without scenario ID, ignoring this interaction`);

          return false;
        } else {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - scenarioIdFound=${JSON.stringify(scenarioIdFound)}, scenarioId=${JSON.stringify(scenarioId)}`);

          const scenarioIDCadName: string = this._scenarioIdCadName
            ? this._scenarioIdCadName
            : 'user.media.id';

          const method = 'PUT';
          const url = `${this._webAppPath}/api/Dialog/${callId}`;

          if (this._interactionIdToScenarioID[callId]) {
            this._loggerService.logger.logTrace(`${cname} - ${fname} - callId=${JSON.stringify(callId)}, ScenarioId=${JSON.stringify(this._interactionIdToScenarioID[callId])}`);

            scenarioId = this._interactionIdToScenarioID[callId];
          } else {
            this._loggerService.logger.logTrace(`${cname} - ${fname} - _interactionIdToScenario has no mapping for callId=${JSON.stringify(callId)}`);

            // If this mapping of callId to scenarioId does not exist, we create it
            // All other tabs will then use it.
            // If we encounter timing issues, it might be worth it to mutex this piece of code using Web Locks API
            const callIdToScenarioId = JSON.parse(this.localStorage.getItem(GlobalConstants.OUTBOUND_CALLID_SCENARIOID_MAPPING)) ?? {};

            if (callIdToScenarioId[callId]) {
              this._loggerService.logger.logTrace(`${cname} - ${fname} - callIdToScenarioId['${JSON.stringify(callId)}']=${JSON.stringify(callIdToScenarioId[callId])}`);

              scenarioId = callIdToScenarioId[callId];
            } else {
              this._loggerService.logger.logTrace(`${cname} - ${fname} - callIdToScenarioId has no mapping for callId=${JSON.stringify(callId)}`);

              scenarioId = uuidv4();
              callIdToScenarioId[callId] = scenarioId;
              this.localStorage.setItem(GlobalConstants.OUTBOUND_CALLID_SCENARIOID_MAPPING, JSON.stringify(callIdToScenarioId));
            }
          }

          this._interactionIdToScenarioID[callId] = scenarioId;

          if (canUpdateTheCall) {
            try {
              this._loggerService.logger.logTrace(`${cname} - ${fname} - canUpdateTheCall=${JSON.stringify(canUpdateTheCall)}`);

              const customerNumberVariableName = this._customerNumberCADECCName;
              const xmlData =
              '<Dialog>' +
                '<requestedAction>UPDATE_CALL_DATA</requestedAction>' +
                '<mediaProperties>' +
                  '<callvariables>' +
                    '<CallVariable>' +
                      `<name>${scenarioIDCadName}</name>` +
                      `<value>${scenarioId}</value>` +
                    '</CallVariable>' +
                    '<CallVariable>' +
                      `<name>${customerNumberVariableName}</name>` +
                      `<value>${customerNumber}</value>` +
                    '</CallVariable>' +
                  '</callvariables>' +
                '</mediaProperties>' +
              '</Dialog>';

              this._loggerService.logger.logInformation(`${cname} - ${fname} - customerNumberVariableName=${JSON.stringify(customerNumberVariableName)}, xmlData=${xmlData}`);

              await this.sendRestRequest(method, url, xmlData).toPromise();

            } catch (error) {
              this._loggerService.logger.logError(`${cname} - ${fname} - Error trying to update call data with scenario ID - Error=${JSON.stringify(error)}`);

              api.sendNotification(`Error trying to update call data with scenario ID and customer number for callId ${callId}. If this error persists, please contact your administrator.`, NOTIFICATION_TYPE.Error);
            }

            return false;
          }
        }
      } else {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - canUpdateTheCall=${JSON.stringify(canUpdateTheCall)}`);

        this._interactionIdToScenarioID[callId] = scenarioId;
      }

      for (const callVariable of callData.mediaProperties.callvariables.CallVariable) {
        this._loggerService.logger.logLoop(`${cname} - ${fname} - callVariable=${JSON.stringify(callVariable)}`);

        CAD_OBJECT[callVariable.name] = { DevName: '', DisplayName: '', Value: callVariable.value };
      }

      if (callData.mediaProperties.wrapUpReason) {
        CAD_OBJECT['disposition'] = { DevName: '', DisplayName: '', Value: callData.mediaProperties.wrapUpReason };
      }


      const details = new RecordItem('', '', '', CAD_OBJECT);

      if (isInbound) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - isInbound=${JSON.stringify(isInbound)}`);

        if (customerNumber) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - customerNumber=${JSON.stringify(customerNumber)}`);

          details.setPhone('', '', customerNumber);
        } else {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - fromAddress=${JSON.stringify(callData.fromAddress.toString())}`);

          details.setPhone('', '', callData.fromAddress.toString());
        }
      } else {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - isInbound=${JSON.stringify(isInbound)}`);

        if (customerNumber) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - customerNumber=${JSON.stringify(customerNumber)}`);

          details.setPhone('', '', customerNumber);
        } else {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - customerNumber=${JSON.stringify(customerNumber)}`);

          details.setPhone('', '', callData.toAddress.toString());
        }
      }

      const interactionID = callData.id.toString();

      if (!this._scenarioList[scenarioId]) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - no mapping in _scenarioList for scenarioId=${JSON.stringify(scenarioId)}, creating empty object`);

        this._scenarioList[scenarioId] = {};
      }

      this._scenarioList[scenarioId][interactionID] = callState;

      if (callState === 'DROPPED' || callState === 'FAILED' || callState === 'WRAP_UP') {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - callState=${callState}`);

        delete this._scenarioList[scenarioId][interactionID];

        if (callState === 'DROPPED' || callState === 'FAILED') {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - callState Dropped or Failed...`);

          this._droppedInteractions.add(interactionID);
          delete this._interactionTypeList[interactionID];
          this.localStorage.setItem(GlobalConstants.INTERACTION_TYPE_LIST, JSON.stringify(this._interactionTypeList));
        }

        if (Object.keys(this._scenarioList[scenarioId]).length === 0) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - _scenarioList is empty for scenarioId=${JSON.stringify(scenarioId)}`);

          delete this._scenarioList[scenarioId];

          // When the scenario - interaction list is empty, we delete the callId from local storage.
          const callIdToScenarioId = JSON.parse(this.localStorage.getItem(GlobalConstants.OUTBOUND_CALLID_SCENARIOID_MAPPING)) ?? {};

          if (callIdToScenarioId[callId]) {
            this._loggerService.logger.logTrace(`${cname} - ${fname} - callIdToScenarioId['${JSON.stringify(callId)}']=${JSON.stringify(callIdToScenarioId[callId])}`);

            this._loggerService.logger.logInformation(`FINESSE - FinesseService - createInteraction - No more interactions for scenario ID: ${scenarioId}. Removing callId ${callId} from local storage.`);

            delete callIdToScenarioId[callId];
            this.localStorage.setItem(GlobalConstants.OUTBOUND_CALLID_SCENARIOID_MAPPING, JSON.stringify(callIdToScenarioId));
          }

          if (Object.keys(this._scenarioList).length === 0) {
            this._loggerService.logger.logTrace(`${cname} - ${fname} - _scenarioList is empty.`);

            this._isOnInteraction = false;
          }
        }

        const userInfo: any = await this.getState();
        this._loggerService.logger.logInformation(`FINESSE - FinesseService - onInteraction - The state info from finesse is ${userInfo.User.state} and the reason code is ${userInfo.User.reasonCodeId}`);
        await this.setPresenceInFramework(userInfo.User.state, userInfo.User.reasonCodeId);
      } else {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - callState=${callState}`);

        if (this._isOnInteraction !== true) {
          this._loggerService.logger.logTrace(`${cname} - ${fname} - _isOnInteraction=${JSON.stringify(this._isOnInteraction)}`);

          this._isOnInteraction = true;
          setTimeout(async () => {
            await api.setPresence(this._onInteractionPresnece);
          }, 100);
        }
      }

      const interactionToSend: IInteraction = {
        interactionId: callData.id.toString(),
        direction: direction,
        channelType: CHANNEL_TYPES.Telephony,
        state: this.getDavinciInteractionState(callState),
        scenarioId: scenarioId,
        details: details,
      };

      api.setInteraction(interactionToSend);

      this._loggerService.logger.logInformation(`${cname} - ${fname} - called setInteraction. Interaction=${JSON.stringify(interactionToSend)}`);

      if (!this._scenarioMap) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - _scenarioMap is undefined. Creating empty object`);

        this._scenarioMap = {};
      }

      if (!this._scenarioMap[interactionToSend.scenarioId]) {
        this._loggerService.logger.logTrace(`${cname} - ${fname} - _scenarioMap has no mapping for scenarioId=${JSON.stringify(interactionToSend.scenarioId)}, creating empty object`);

        this._scenarioMap[interactionToSend.scenarioId] = {};
      }
      this._scenarioMap[interactionToSend.scenarioId][interactionToSend.interactionId] = interactionToSend;

      return true;
    } catch (error) {
      this._loggerService.logger.logError(`FINESSE - FinesseService - createInteraction - Error : ${error.message || error}`);
    } finally {
      this._loggerService.logger.logDebug('FINESSE - FinesseService - createInteraction - END');
    }
  }
}
